import { createSlice } from "@reduxjs/toolkit";

const initialState = { contentTypesMatch: "true" };
export const ScenarioFormSlice = createSlice({
  name: "ScenarioForm",
  initialState,
  reducers: {
    setContentTypesMatch: (state, action) => {
      state.contentTypesMatch = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setContentTypesMatch } = ScenarioFormSlice.actions;

export default ScenarioFormSlice.reducer;
