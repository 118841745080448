import sysConfig from "@configs/sysConfig";
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const environmentApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Environment"],
  reducerPath: "environmentApi",
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getEnvironment: build.query({
      query: () => sysConfig.environmentEndpoint,
      providesTags: ["Environment"]
    })
  })
});

export const { useGetEnvironmentQuery, useLazyGetEnvironmentQuery } =
  environmentApi;
