// ** RootMiddleware, rootReducer import
import { setupListeners } from "@reduxjs/toolkit/query";
import rootMiddleware from "../middlewares/rootMiddleware";
import rootReducer from "../reducers/rootReducer";

// ** redux toolkit
import { configureStore } from "@reduxjs/toolkit";
// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: rootReducer,
  // Turn off devtools in prod, or pass options in dev
  devTools:
    process.env?.REACT_APP_NODE_ENV === "production" ||
    process.env?.REACT_APP_NODE_ENV === "onprem"
      ? false
      : true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "loadTestUploadFileSlice/setVars",
          "loadTestUploadFileSlice/setDelimeter"
        ],
        // Ignore these field paths in all actions
        ignoredActionPaths: [
          "meta.arg",
          "payload.timestamp",
          "meta.baseQueryMeta"
        ],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates", "loadTestUploadFileSlice.vars"]
      }
    }).concat(rootMiddleware)
});

setupListeners(store.dispatch);

export { store };
