import { createApi } from "@reduxjs/toolkit/query/react";
import sysConfig from "@src/configs/sysConfig";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const transactionApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Transaction"],
  reducerPath: "transactionApi",
  keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    // A query endpoint with no arguments but with query params
    getTransaction: build.query({
      query: (arg) => {
        const { page } = arg;
        return {
          url: sysConfig.paymentTransactionEndpoint,
          params: {
            page: page
          }
        };
      },
      providesTags: ["Transaction"]
    })
  })
});

export const { useLazyGetTransactionQuery } = transactionApi;
