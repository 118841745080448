import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import useJwt from "@src/auth/jwt/useJwt";
import sysConfig from "@src/configs/sysConfig";

const baseQueryConfig = retry(
  fetchBaseQuery({
    // Fill in your own server starting URL here
    baseUrl: sysConfig.backendURL,
    prepareHeaders: (headers) => {
      // https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#common-usage-patterns
      // TODO : get token not in useJWT but from redux state when authTokens slice implemented
      // if url params contain access_token and refresh_token, use them

      // if url params contain access_token and refresh_token, use them
      const urlParams = new URLSearchParams(window.location.search);

      const token = useJwt.getToken();
      {
        /* TODO: ADD selfhosted enterprise check */
      }
      if (!token) {
        return headers;
      }

      {
        /* TODO: ADD selfhosted enterprise check */
      }

      return headers;

      // If we have a token set in state, let's assume that we should be passing it.

      return headers;
    }
  })
);

export default baseQueryConfig;
