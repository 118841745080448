import { createApi } from "@reduxjs/toolkit/query/react";
import sysConfig from "@src/configs/sysConfig";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";
import { setPricingSliceData } from "@src/views/pricing/slice/index";
import { setDashboardSliceData } from "@src/views/dashboard/slice/index";
import { handleLogout } from "@src/redux/actions/auth";
import Mixpanel from "mixpanel-browser";

export const dashboardApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Dashboard", "Engines"],
  reducerPath: "dashboardApi",
  keepUnusedDataFor: 10,
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getDashboard: build.query({
      query: () =>
        sysConfig.isSelfHosted ? "/selfhosted/dashboard/" : "/dashboard/",
      providesTags: ["Dashboard"],
      extraOptions: { maxRetries: 1 },
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const cacheEntry = await cacheDataLoaded;
        dispatch(setDashboardSliceData(cacheEntry?.data));
        if (cacheEntry?.data?.user?.balances)
          dispatch(
            setPricingSliceData(
              cacheEntry?.data?.user?.balances[
                Object.keys(cacheEntry?.data?.user?.balances)[0]
              ]
            )
          );
        if (
          sysConfig.isSelfHosted &&
          !cacheEntry?.data?.is_enterprise_unlocked &&
          localStorage.getItem("userData")
        ) {
          dispatch(handleLogout());
        }
        if (
          sysConfig.mixpanelToken &&
          cacheEntry?.data?.user?.id &&
          !(localStorage.getItem("mp_is_synced") === "true")
        ) {
          Mixpanel.init(sysConfig.mixpanelToken, {
            api_host: "https://api.mixpanel.com"
          });

          Mixpanel.alias(cacheEntry?.data?.user?.id);

          Mixpanel.track("User who came from landing page, logined", {
            "User ID": cacheEntry?.data?.user?.id
          });

          localStorage.setItem("mp_is_synced", "true");
        }
      }
    }),
    getDashboardUsage: build.query({
      query: ({ interval }) => "/dashboard/usage/?interval=" + interval,
      tag: "DashboardUsage"
    }),
    getEngines: build.query({
      query: () => "/selfhosted/engines/",
      providesTags: ["Engines"]
    }),
    removeEngine: build.mutation({
      query: (engineId) => ({
        url: "/selfhosted/engines/" + engineId + "/",
        method: "DELETE"
      }),
      invalidatesTags: ["Dashboard", "Engines"]
    }),
    openDistributedMode: build.mutation({
      query: (key) => ({
        url: "/selfhosted/unlockdist/",
        method: "POST",
        body: { license_key: key }
      }),
      invalidatesTags: ["Dashboard", "Engines"]
    }),
    upgradeEnterprise: build.mutation({
      query: (key) => ({
        url: "/selfhosted/upgrade_enterprise/",
        method: "POST",
        body: { license_key: key }
      }),
      invalidatesTags: ["Dashboard", "Engines"]
    }),
    setHardLimit: build.mutation({
      query: (hardLimit) => ({
        url: "/payment/hard_limit/",
        method: "POST",
        body: { hard_limit_dollars: hardLimit }
      }),
      invalidatesTags: ["Dashboard"]
    }),
    Subscribe: build.mutation({
      query: ({ initialSubscription, planId, isCancel }) => ({
        url: "/payment/subscription/update_plan/",
        method: "POST",
        body: !isCancel
          ? {
              initial_subscription: initialSubscription,
              payment_gateway_plan_id: planId
            }
          : {
              payment_gateway_plan_id: planId,
              isCancel: isCancel
            }
      }),
      invalidatesTags: ["Dashboard"]
    })
  })
});

export const {
  useGetDashboardQuery,
  useGetDashboardUsageQuery,
  useOpenDistributedModeMutation,
  useUpgradeEnterpriseMutation,
  useGetEnginesQuery,
  useLazyGetEnginesQuery,
  useRemoveEngineMutation,
  useLazyGetDashboardQuery,
  useSetHardLimitMutation,
  useSubscribeMutation
} = dashboardApi;
