import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

const isSelfHosted = (sysConfig) => {
  return sysConfig?.isSelfHosted;
};

export const ssoApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["ssoLink", "ssoConfig"],
  reducerPath: "ssoApi",
  keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 20,
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getSSOLink: build.query({
      query: (data) =>
        `/account/sso/?login_id=${data?.loginId}&idp=${data?.idp}`,
      providesTags: ["ssoLink"]
    }),
    setSSOConfig: build.mutation({
      query: (data) => ({
        url: "/account/sso/config/",
        method: "POST",
        body: {
          entity_id: data.issuer,
          sso_url: data.samleEndpoint,
          slo_url: data.sloEndpoint,
          x509_certificate: data.x509Certificate,
          idp: data.idp,
          login_id: data.loginId,
          name: data.name,
          login_url: data.loginURL
        }
      }),
      invalidatesTags: ["ssoConfig"]
    }),
    getSSOConfig: build.query({
      query: () => "/account/sso/config/",
      providesTags: ["ssoConfig"]
    }),
    updateSSOConfig: build.mutation({
      query: (data) => ({
        url: "/account/sso/config/",
        method: "PUT",
        body: {
          entity_id: data.issuer,
          sso_url: data.samleEndpoint,
          slo_url: data.sloEndpoint,
          x509_certificate: data.x509Certificate,
          idp: data.idp,
          login_id: data.loginId,
          name: data.name,
          login_url: data.loginURL
        }
      }),
      invalidatesTags: ["ssoConfig"]
    }),
    deleteSSOConfig: build.mutation({
      query: (data) => ({
        url:
          "/account/sso/config/?idp=" + data.idp + "&login_id=" + data.loginId,
        method: "DELETE"
      }),
      invalidatesTags: ["ssoConfig"]
    })
  })
});

export const {
  useGetSSOLinkQuery,
  useLazyGetSSOLinkQuery,
  useSetSSOConfigMutation,
  useGetSSOConfigQuery,
  useUpdateSSOConfigMutation,
  useDeleteSSOConfigMutation
} = ssoApi;
