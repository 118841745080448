import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const integrationsApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Integration"],
  reducerPath: "integrationsApi",
  keepUnusedDataFor: 20,
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getIntegrations: build.query({
      query: (pageNumber) => `/alerts/integration?page_number=${pageNumber}`,
      providesTags: ["Integration"]
    }),
    addIntegration: build.mutation({
      query: (body) => ({
        url: "/alerts/integration",
        method: "POST",
        body: body
      }),
      invalidatesTags: ["Integration"]
    }),
    removeIntegration: build.mutation({
      query: (uid) => ({
        url: `/alerts/integration?uid=${uid}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Integration"]
    }),
    putIntegration: build.mutation({
      query: (body) => ({
        url: `/alerts/integration?uid=${body.uid}`,
        method: "PUT",
        body: body
      }),
      invalidatesTags: ["Integration"]
    }),
    createSlackIntegration: build.query({
      query: ({ code, state }) =>
        `/alerts/slack/auth/?code=${code}&state=${state}`,
      invalidatesTags: ["Integration"]
    }),
    getSlackChannels: build.query({
      query: ({ uid }) => `/alerts/slack/channel/?integration_uid=${uid}`
    }),
    testIntegration: build.mutation({
      query: (uid) => ({
        url: `/alerts/integration/test?integration_uid=${uid}`,
        method: "POST"
      }),
      invalidatesTags: ["Integration"]
    }),
    getAllIntegrations: build.query({
      query: () => "/alerts/integration/all",
      providesTags: ["Integration"]
    })
  })
});

export const {
  useGetIntegrationsQuery,
  useAddIntegrationMutation,
  useRemoveIntegrationMutation,
  usePutIntegrationMutation,
  useCreateSlackIntegrationQuery,
  useGetSlackChannelsQuery,
  useTestIntegrationMutation,
  useGetAllIntegrationsQuery
} = integrationsApi;
