import { createApi } from "@reduxjs/toolkit/query/react";
import sysConfig from "@src/configs/sysConfig";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const accountApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Acount", "Support"],
  reducerPath: "accountApi",
  keepUnusedDataFor: 1,
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getAccount: build.query({
      query: () => "/account/user/",
      providesTags: ["Acount"]
    }),
    // get Email subscription
    getEmailSubscription: build.query({
      query: ({ token }) => `/account/email_subscription/?token=${token}`
    }),
    // postMailRegister
    postMailRegister: build.mutation({
      query: ({ userId, mailVersionId, isEmailUnsubscribe, returnUrl }) => ({
        url: `${sysConfig.backendURL}/transactions/mail_register?user_id=${userId}&mail_version_id=${mailVersionId}&unsubscribe=${isEmailUnsubscribe}&return_url=${returnUrl}`,
        method: "POST"
      })
    }),
    // update email subscription
    updateEmailSubscription: build.mutation({
      query: (body) => ({
        url: "/account/email_subscription/",
        method: "POST",
        body: body
      })
    }),
    //selfhosted data collect part
    addUserDataSelfhosted: build.mutation({
      query: (body) => ({
        url: "/account/selfhosted_stats/",
        method: "POST",
        body: body
      })
    }),
    updateUserAccount: build.mutation({
      query: (body) => ({
        url: "/account/user/",
        method: "PATCH",
        body: body
      }),
      invalidatesTags: ["Acount"]
    }),
    revokeApikey: build.mutation({
      query: () => ({
        url: "/apikey/revoke/",
        method: "PUT"
      }),
      invalidatesTags: ["Acount"]
    }),
    revokeLicense: build.mutation({
      query: () => ({
        url: "/licensekey/revoke/",
        method: "PUT"
      }),
      invalidatesTags: ["Acount"]
    }),
    createLicense: build.mutation({
      query: (body) => ({
        url: "/licensekey/",
        method: "POST",
        body: body
      }),
      invalidatesTags: ["Acount"]
    }),
    getStatus: build.query({
      query: () => "/account/support/status/",
      providesTags: ["Support"]
    }),
    createSupport: build.mutation({
      query: (supportData) => ({
        url: "/account/support/create/",
        method: "POST",
        body: supportData
      }),
      invalidatesTags: ["Support"]
    })
  })
});

export const {
  useRevokeApikeyMutation,
  useRevokeLicenseMutation,
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useUpdateUserAccountMutation,
  useGetEmailSubscriptionQuery,
  usePostMailRegisterMutation,
  useUpdateEmailSubscriptionMutation,
  useCreateApikeyMutation,
  useCreateLicenseMutation,
  useAddUserDataSelfhostedMutation,
  useGetStatusQuery,
  useCreateSupportMutation
} = accountApi;
