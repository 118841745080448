import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const uploadMultipartApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["UploadMultipart"],
  reducerPath: "uploadMultipartApi",
  keepUnusedDataFor: 1,
  endpoints: (build) => ({
    // A query endpoint with  arguments

    uploadBinaryFile: build.mutation({
      query: ({ formedData, file }) => {
        return {
          url: "/upload/multipart/",
          method: "POST",
          body: formedData,
          header: {
            "Content-Type": "multipart/form-data"
          },
          formData: true
        };
      },
      invalidatesTags: ["UploadMultipart"]
    })
  })
});

export const { useUploadBinaryFileMutation } = uploadMultipartApi;
