// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig";

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem("menuCollapsed");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  headerWarnings: [],
  isDarkMode: false
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HANDLE_CONTENT_WIDTH":
      return { ...state, contentWidth: action.value };
    case "HANDLE_MENU_COLLAPSED":
      window.localStorage.setItem("menuCollapsed", action.value);
      return { ...state, menuCollapsed: action.value };
    case "HANDLE_MENU_HIDDEN":
      return { ...state, menuHidden: action.value };
    case "HANDLE_RTL":
      return { ...state, isRTL: action.value };
    case "HANDLE_THEME_CHANGE":
      return { ...state, isDarkMode: action.value };

    case "HANDLE_HEADER_WARNINGS":
      return {
        ...state,
        headerWarnings:
          // headerWarnings is array of objects [{type: "PAYMENT_CANCELLED_WARNING"}] and etc. save only one warning of each type
          state.headerWarnings
            .filter((warning) => warning.type !== action.value.type)
            .concat(action.value)
      };
    case "HANDLE_HEADER_WARNING_REMOVE":
      return {
        ...state,
        headerWarnings: state.headerWarnings.filter(
          (warning) => warning.type !== action.value.type
        )
      };
    default:
      return state;
  }
};

export default layoutReducer;
