import { createSlice } from "@reduxjs/toolkit";

const initialState = { account: null };
export const accountSlice = createSlice({
  name: "accountSlice",
  initialState,
  reducers: {
    setAccountSliceData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.account = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAccountSliceData } = accountSlice.actions;

export default accountSlice.reducer;
