import sysConfig from "@configs/sysConfig";

export default {
  baseURL: sysConfig.backendURL,

  // ** Auth Endpoints
  loginEndpoint: "/account/login/",
  socialGoogleLoginEndpoint: "/account/google/",
  registerEndpoint: "/account/register/",
  refreshEndpoint: "/account/token/refresh/",
  logoutEndpoint: "/account/logout/",

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "access",
  storageRefreshTokenKeyName: "refresh"
};
