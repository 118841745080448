import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from "reactstrap";
import { BiSearch } from "react-icons/bi"; // Assuming you're using react-icons
import { useEffect, useState } from "react";
import { clsx } from "clsx";

const NewInput = ({
  addons,
  search,
  placeholder,
  bsSize,
  innerStyle,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { isFloatingLabel, label, defaultValue, value } = props;

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      setIsFocused(false);
    }
  };

  // if default value is passed, set isFocused to true
  useEffect(() => {
    if (defaultValue || value) {
      setIsFocused(true);
    }
  }, [defaultValue, value]);

  if (!addons && !search) {
    if (isFloatingLabel) {
      return (
        <FormGroup className="floating-label-input">
          <Label className={isFocused ? "focused" : ""}>{label}</Label>
          <Input
            placeholder={placeholder}
            bsSize={bsSize}
            className={innerStyle}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          />
        </FormGroup>
      );
    } else {
      return (
        <Input
          placeholder={placeholder}
          bsSize={bsSize}
          className={innerStyle}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
      );
    }
  }

  return (
    <InputGroup
      size={bsSize}
      className={clsx(
        "input-group-merge tw-flex-nowrap",
        innerStyle,
        isFloatingLabel ? "floating-label-input tw-w-full" : ""
      )}>
      {search && (
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <BiSearch
              size={bsSize === "sm" ? 16 : 20}
              className="tw-text-secondary-base"
            />
          </InputGroupText>
        </InputGroupAddon>
      )}
      {isFloatingLabel ? (
        <>
          <Label htmlFor={props.id} className={isFocused ? "focused" : ""}>
            {label}
          </Label>
          <Input
            placeholder={placeholder ? placeholder : search ? "Search" : ""}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          />
          {addons}
        </>
      ) : (
        <>
          <Input
            placeholder={placeholder ? placeholder : search ? "Search" : ""}
            {...props}
          />
          {addons}
        </>
      )}
    </InputGroup>
  );
};

export default NewInput;
