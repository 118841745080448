import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";
import moment from "moment";

export const tracingApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["TracingDetails,TracingList", "TracingFilter"],
  reducerPath: "tracingApi",
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getTracingDetails: build.query({
      // test traceId =66204
      query: ({ traceId }) => `/dist_tracing/trace/${traceId}/`,
      providesTags: ["TracingDetails"]
    }),
    getTracings: build.mutation({
      query: ({
        operations,
        tags,
        startTime,
        endTime,
        minDuration,
        maxDuration,
        maxResults = 1,
        monitoringId,
        timeRange,
        timeBox,
        minSpanCount,
        maxSpanCount
      }) => {
        return {
          url: `/dist_tracing/trace/`,
          method: "POST",
          body: {
            operations: operations,
            attributes: tags,
            start_time: timeRange
              ? moment()
                  .millisecond(0)
                  .subtract(timeRange, "milliseconds")
                  .valueOf()
              : timeBox?.startDate,
            end_time: timeRange
              ? moment().millisecond(0).valueOf()
              : timeBox?.endDate,
            min_duration_ms: minDuration,
            max_duration_ms: maxDuration,
            monitoring_id: monitoringId,
            limit: maxResults,
            min_span_count: minSpanCount,
            max_span_count: maxSpanCount
          },
          providesTags: ["TracingList", "TracingFilter"],
          invalidatesTags: ["TracingFilter"],
          extraOptions: { maxRetries: 1 }
        };
      }
    }),
    // {{backend_url}}/dist_tracing/trace_metadata/?monitoring_id=abc94c98-00c2-41ac-b10b-0bf3cc94c2e7
    getTraceMetaData: build.query({
      query: ({ monitoringId }) => ({
        url: `/dist_tracing/trace_metadata/`,
        params: {
          monitoring_id: monitoringId
        }
      })
    }),
    removeTracing: build.mutation({
      query: (data) => ({
        //TODO url is waiting
        url: "",
        method: "DELETE"
      }),
      invalidatesTags: ["TracingList"]
    }),
    // get cluster details from /alaz/cluster/{monitor_id}/
    getClusterDetails: build.query({
      query: ({ monitoringId }) => `/alaz/cluster?monitoring_id=${monitoringId}`
    })
  })
});

export const {
  useGetTracingsMutation,
  useGetTracingDetailsQuery,
  useRemoveTracingMutation,
  useGetTraceMetaDataQuery,
  useGetClusterDetailsQuery
} = tracingApi;
