import { syntaxTree } from "@codemirror/language";
import { renderToString } from "react-dom/server";
import sanitizeHtml from "sanitize-html";
import { hover } from "@src/components/AnteonEditor/hover";

const dontCompleteIn = [
  "TemplateString",
  "LineComment",
  "BlockComment",
  "VariableDefinition",
  "PropertyDefinition"
];

function countStartingBraces(str) {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === "{") {
      count++;
    } else if (str[i].match(/[ \t]/)) {
      count = 0;
    } else if (str[i].match(/\w/)) {
      break;
    }
  }
  return count;
}
function countEndBraces(str) {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === "}") {
      count++;
    } else {
      break;
    }
  }
  return count;
}
const tagOptions = (
  braceletCount,
  braceletCountAfter,
  completeSuggestions,
  getEnvironmentName
) => {
  const info = (tag) => {
    const dom = document.createElement("div");
    const jsxElement = hover(tag, "title", "description", getEnvironmentName);
    dom.innerHTML = renderToString(jsxElement);
    const htmlsanitized = sanitizeHtml(dom.innerHTML, {
      allowedTags: []
    });
    return htmlsanitized;
  };
  return completeSuggestions
    .map((tag) => {
      if (!tag.title) {
        return null;
      }
      return {
        label: tag.title,
        apply:
          `{{`.slice(braceletCount) +
          tag.title +
          "}}".slice(countEndBraces(braceletCountAfter)),
        type: "auto",
        info: info(tag)
      };
    })
    .filter((tag) => tag !== null);
};

export default function completeJSDoc(context, completeSuggestions) {
  let nodeBefore = syntaxTree(context?.state).resolveInner(0, -1);
  if (dontCompleteIn.includes(nodeBefore.name)) {
    return null;
  }
  let textBefore = context?.state.sliceDoc(nodeBefore.from, context.pos);
  let textAfter = context?.state.sliceDoc(context.pos, context.pos + 2);
  ///[^a-zA-Z*\}*:*\s*\"*]*\{+\w*$/    previous regex it is also working but complex
  let tagBefore = /\{+\w*\s*[^\}\{}]*$/.exec(textBefore);
  if (!tagBefore) return null;
  const splited = tagBefore.input.split("}");
  const braceletCount = countStartingBraces(tagBefore[0]);
  if (!tagBefore && !context.explicit) return null;
  return {
    from: nodeBefore.from + tagBefore.index + braceletCount,
    options: tagOptions(braceletCount, textAfter, completeSuggestions),
    validFor: /{*\w*}*$/
  };
}
