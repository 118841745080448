// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import scenarioFormReducer from "@src/components/TestPlan/ScenarioBuild/ScenarioForm/slice/scenarioForm";
import analyzeFailures from "@src/redux/reducers/loadTestResult/analyzeFailures/index";
import loadTestUploadFileSlice from "@src/redux/reducers/loadTestUploadFile/slice/loadTestUploadFileSlice";
import loadTestUploadFileVariablesSlice from "@src/redux/reducers/loadTestUploadFileVariablesSlice";
import authTokensReducer from "@src/redux/storeConfig/authTokensSlice";
import tourReducer from "@src/views/about/slice/index";
import accountReducer from "@src/views/account-settings/slice/index";
import dashboardReducer from "@src/views/dashboard/slice/index";

import pricingReducer from "@src/views/pricing/slice/index";
import assertionsReducer from "@store/reducers/assertions/index";
import serviceMapReducer from "@store/reducers/serviceMap/index";

import environment from "@store/reducers/testSuite/index";
import auth from "./auth";
import layout from "./layout";
import navbar from "./navbar";
import nodeSearchSlice from "@store/reducers/serviceMap/nodeSearchReducer";

// Import the API object
import { ssoApi } from "@src/components/LoginButtonOneLoginSSO/api/loginSSOApiSlice";
import serviceMapNodesSliceReducer from "@src/components/ServiceMap/slice/index";
import { accountApi } from "@src/views/account-settings/api/accountApiSlice";
import { transactionApi } from "@src/views/account-settings/usage-history/api/transactionApiSlice";
import { alertApi } from "@src/views/alerts/api/alertsApiSlice";
import { dashboardApi } from "@src/views/dashboard/api/dashboardApiSlice";
import { integrationsApi } from "@src/views/integrations/api/integrationsApiSlice";
import { aiAnalyseApi } from "@src/views/load/api/aiAnalyseApiSlice";
import { uploadMultipartApi } from "@src/views/load/api/uploadMultipartApiSlice";
import { environmentApi } from "@src/views/load/environments/api/environmentApiSlice";
import { monitorApi } from "@src/views/monitoring/api/monitorApiSlice";
import { pricingApi } from "@src/views/pricing/api/pricingApiSlice";
import { teamMembersApi } from "@src/views/team-settings/api/teamMembersApiSlice";
import { tracingApi } from "@src/views/tracing/api/tracingApiSlice";
const appReducer = combineReducers({
  auth,
  navbar,
  layout,
  environment,
  tourData: tourReducer,
  loadTestUploadFileVariablesSlice,
  loadTestUploadFileSlice,
  analyzeFailures,
  authTokens: authTokensReducer,
  dashboardData: dashboardReducer,
  serviceMapNodesSlice: serviceMapNodesSliceReducer,
  scenarioFormData: scenarioFormReducer,
  pricingData: pricingReducer,
  accountData: accountReducer,
  nodeSearchData: nodeSearchSlice,
  [ssoApi.reducerPath]: ssoApi.reducer,
  assertionsReducer,
  serviceMapReducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [teamMembersApi.reducerPath]: teamMembersApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [uploadMultipartApi.reducerPath]: uploadMultipartApi.reducer,
  [pricingApi.reducerPath]: pricingApi.reducer,
  [aiAnalyseApi.reducerPath]: aiAnalyseApi.reducer,
  [monitorApi.reducerPath]: monitorApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [environmentApi.reducerPath]: environmentApi.reducer,
  [alertApi.reducerPath]: alertApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [tracingApi.reducerPath]: tracingApi.reducer
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_REDUX") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
