import {
  ErrorToast,
  showToast,
  SuccessToast
} from "@customLayouts/components/ToastContent";
import AnteonEditor from "@src/components/AnteonEditor";
import sysConfig from "@src/configs/sysConfig";
import moment from "moment";
import { Fragment, useRef, useState } from "react";
import { Collapse } from "react-collapse";
import { Copy, HelpCircle, Info } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip
} from "@src/components/ReactstrapComponents";
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000)?.toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html?.replace(/<\/?[^>]+(>|$)/g, "");

export const domainValidatorRegex =
  /^((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/i;

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 ** en-GB makes day/(month)/year format however en-US makes month/day/year format instead
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
  },
  isGB = false
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat(isGB ? "en-GB" : "en-US", formatting).format(
    new Date(value)
  );
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
{
  /* TODO: ADD selfhosted enterprise check */
}
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => {
  try {
    return JSON.parse(localStorage.getItem("userData"));
  } catch (e) {
    return JSON.parse("{}");
  }
};
JSON.parse(localStorage.getItem("userData") || "{}");
export const saveUserData = (data) =>
  localStorage.setItem("userData", JSON.stringify(data));

export const getShowEmailMarketingCheckModal = () => {
  try {
    return JSON.parse(localStorage.getItem("show-email-marketing-modal"));
  } catch (e) {
    return "false";
  }
};
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#0ab89b", // for option hover bg-color
    primary: "#0ab89b", // for selected option bg-color
    neutral10: "#0ab89b", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
});

export const formatPrice = (value) => {
  if (value === null || value === undefined) {
    return "-";
  }

  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
};

export const formatNumber = (value) => {
  if (!value) {
    return "No Data Before";
  }

  return Math.trunc(value).toLocaleString("en-US");
};

export const formatNumberExactly = (value) => {
  if (value === null || value === undefined) {
    return "-";
  }

  return value.toLocaleString("en-US");
};

export const abbrvNumber = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3)?.toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6)?.toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9)?.toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12)?.toFixed(1) + "T";
};

export const calculatePerc = (value, total) => {
  return Math.round((value / total) * 100);
};

export const calculateAvg = (oldAvg, count, newStat) => {
  return (oldAvg * count + newStat) / (count + 1);
};

export const getMin = (val1, val2) => {
  return val1 < val2 ? val1 : val2;
};

export const getMax = (val1, val2) => {
  return val1 > val2 ? val1 : val2;
};

/**
 * Helper function to aggregate errored requests to two groups.
 * 1- Timeout errors.
 * 2- Network errors.
 * Thanks to aggregation we prevent too many error type columns.
 */
export const aggregateErrors = (errors) => {
  let error_dist = { timeout: 0, network: 0 };

  if (errors) {
    if (errors?.server !== undefined) {
      for (const [error, count] of Object.entries(errors.server)) {
        if (error.includes("timeout")) {
          error_dist.timeout += count;
        } else {
          error_dist.network += count;
        }
      }
    } else {
      // For backward compability
      for (const [error, count] of Object.entries(errors)) {
        if (error.includes("timeout")) {
          error_dist.timeout += count;
        } else {
          error_dist.network += count;
        }
      }
    }
  }

  return error_dist;
};

/**
 * Helper function to aggregate errored requests to two groups.
 * 1- Assertion errors.
 * 2- Server errors.
 * Thanks to aggregation we prevent too many error type columns.
 */
export const aggregateAssertionErrors = (errors) => {
  let error_dist = { assertion: 0, server: 0 };

  if (errors) {
    // For Server errors
    if (errors?.server) {
      for (const [error, count] of Object.entries(errors.server)) {
        error_dist.server += count;
      }
    }
    if (errors?.assertion) {
      // For Assertion errors
      for (const [error, count] of Object.entries(errors.assertion)) {
        error_dist.assertion += count;
      }
    }
  }

  return error_dist;
};

export const toHHMMSS = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  var seconds = seconds - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export const durationStrFormatting = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  var seconds = seconds - hours * 3600 - minutes * 60;

  var result = "";
  if (hours > 0) {
    result += hours + "h ";
  }
  if (minutes > 0) {
    result += minutes + "m ";
  }
  if (seconds > 0) {
    result += seconds + "s ";
  }

  if (result === "") {
    result = "0s";
  }
  return result;
};

export const generateAvatar = (text) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = 200;
  canvas.height = 200;

  // Draw background
  context.fillStyle = "#0ab89b";
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw text
  context.font = "bold 100px Assistant";
  context.fillStyle = "white";
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(text, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL("image/png");
};

export const validateEmail = (email) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const handleAxiosError = (
  e,
  err_code,
  toastDuration = 5000,
  additionalText
) => {
  if (e?.response?.data?.msg || e?.data?.msg) {
    showToast(
      <ErrorToast
        desc={
          (e?.response?.data?.msg || e?.data?.msg) +
          (additionalText ? "" : " " + additionalText)
        }
      />,
      toastDuration,
      "error"
    );
  } else if (e?.response?.data?.password1 || e?.response?.data?.password2) {
    showToast(
      <ErrorToast
        desc={
          e?.response?.data?.password1[0] || e?.response?.data?.password2[0]
        }
      />,
      toastDuration,
      "error"
    );
  } else {
    showToast(
      <ErrorToast desc={`Error Code: ${err_code}`} />,
      toastDuration,
      "error"
    );
  }
};

export const docButton = (path, iconSize = 16) => {
  let url = path ? sysConfig.docsURL + path : sysConfig.docsURL;
  return (
    <a
      href={url}
      target="_blank"
      style={{ color: "gray" }}
      className="alignCenter"
      rel="noreferrer">
      <HelpCircle
        size={iconSize}
        className="ml5 cursorPointer"
        id="docButton"
      />

      <UncontrolledTooltip placement="top" target="docButton">
        Docs
      </UncontrolledTooltip>
    </a>
  );
};

export const tooltip = (
  target,
  size,
  placement,
  text,
  className = "",
  autohide = true,
  color = "secondary"
) => {
  return (
    <>
      <Info id={target} size={size} className={className} />
      <UncontrolledTooltip
        placement={placement}
        target={target}
        autohide={autohide}
        dangerouslySetInnerHTML={{ __html: text }}></UncontrolledTooltip>
    </>
  );
};

export const extractGeoName = (geo) => {
  return geo.split(":")[1];
};

export const extractGeoKey = (geo) => {
  return geo.split(":")[0];
};

export const buildCodeToCityMap = (locations) => {
  let codeToCity = {};
  Object.keys(locations).forEach((key) => {
    let continentCode = extractGeoKey(key);

    let countries = locations[key];
    Object.keys(countries).forEach((key) => {
      let countryCode = extractGeoKey(key);

      let cityOrStates = countries[key];
      Object.keys(cityOrStates).forEach((key) => {
        if (cityOrStates[key] instanceof Object) {
          let stateCode = extractGeoKey(key);

          let cities = cityOrStates[key];
          Object.keys(cities).forEach((key) => {
            let cityCode = extractGeoKey(cities[key]);
            let city = extractGeoName(cities[key]);
            codeToCity[
              continentCode +
                "." +
                countryCode +
                "." +
                stateCode +
                "." +
                cityCode
            ] = city;
          });
        } else {
          let cityCode = extractGeoKey(cityOrStates[key]);
          let city = extractGeoName(cityOrStates[key]);
          codeToCity[continentCode + "." + countryCode + "." + cityCode] = city;
        }
      });
    });
  });
  return codeToCity;
};

export const hasJsonStructure = (str) => {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype?.toString.call(result);
    return type === "[object Object]" || type === "[object Array]";
  } catch (err) {
    return false;
  }
};

export const prettyPrintXml = (sourceXml) => {
  var xmlDoc = new DOMParser().parseFromString(sourceXml, "application/xml");
  var xsltDoc = new DOMParser().parseFromString(
    [
      // describes how we want to modify the XML - indent everything
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*" />',
      '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      '    <xsl:value-of select="normalize-space(.)" />',
      "  </xsl:template>",
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*" /></xsl:copy>',
      "  </xsl:template>",
      '  <xsl:output indent="yes" />',
      "</xsl:stylesheet>"
    ].join("\n"),
    "application/xml"
  );

  var xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xsltDoc);
  var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  var resultXml = new XMLSerializer().serializeToString(resultDoc);
  return resultXml;
};

export const statusCodeToText = (code) => {
  code = code?.toString();
  let codeColor = "text-success";
  if (code.startsWith("3")) {
    codeColor = "text-info";
  } else if (code.startsWith("4")) {
    codeColor = "text-warning";
  } else if (code.startsWith("5")) {
    codeColor = "text-danger";
  }

  return <span className={codeColor}>{code}</span>;
};

export const findAndGetDuplicateStringsInArr = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) != index);
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)?.replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    )?.toString(16)
  );
};

export const isEmptyStr = (str) => {
  if (str === undefined || str === null || str.length === 0) {
    return true;
  }
};

export const onlyCharAndHypenFieldOnKeyPress = (e, prevVal = "") => {
  let kcode = !e.charCode ? e.which : e.charCode;

  // Allow directly for backspace and tab
  if (kcode == 8 || kcode == 9) {
    return true;
  }

  if (
    !isEmptyStr(prevVal) &&
    (kcode == 45 ||
      kcode == 95 ||
      (kcode > 64 && kcode < 91) ||
      (kcode > 96 && kcode < 123) ||
      (kcode > 47 && kcode < 58))
  ) {
    // Allow - _ [0-9] [a-zA-Z] if this is not the first character of the string

    return true;
  } else if ((kcode > 64 && kcode < 91) || (kcode > 96 && kcode < 123)) {
    // Allow only [a-zA-Z] if this is the first character of the string
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const startsWithRegex = (str, regex) => {
  return regex?.test(str) && str?.match(regex).index === 0;
};

// ** format unix timestamps as human readable texts **
export const timeDifference = (timestamp) => {
  const currentDate = new Date();
  const date = new Date(timestamp);
  const seconds = Math.floor((currentDate - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} min ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 7) {
    const dayOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ][date.getDay()];
    return `${days + 1} days ago`;
    // return `Last ${dayOfWeek} at ${date.toLocaleTimeString([], {
    //   hour: "numeric",
    //   minute: "2-digit",
    // })}`;
  } else if (days < 14) {
    // return `${days} days ago`;
    return `1 week ago`;
  } else {
    // return (
    //   date.toLocaleDateString() +
    //   " at " +
    //   date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })
    // );
    return formatDate(date, false, true);
  }
};

const isDateTimeStampExist = (dateTimeStamp) => {
  return (
    dateTimeStamp !== "-" &&
    dateTimeStamp !== null &&
    typeof dateTimeStamp != undefined
  );
};

export const ShowDateTimeWithTooltip = ({ id, dateTimeStamp }) => {
  return (
    <>
      <span id={id}>
        {!isDateTimeStampExist(dateTimeStamp)
          ? "-"
          : timeDifference(dateTimeStamp)}
      </span>
      {!isDateTimeStampExist(dateTimeStamp) ? null : (
        <UncontrolledTooltip placement="top" target={id}>
          {formatDate(
            dateTimeStamp,
            {
              month: "numeric",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric"
            },
            true
          )}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export const truncate = (str, n = 20) => {
  return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
};

export const copyToClipBoardOnClick = (e, str) => {
  e?.preventDefault();
  navigator?.clipboard?.writeText(str);
  showToast(<SuccessToast desc="Copied to clipboard" />, 3000);
};

export const isHttp = () => {
  return window?.location?.protocol === "http:";
};

export const GetBodyWithCodeEditor = ({
  body,
  contentType,
  fieldName,
  allText
}) => {
  const formatBody = (str) => {
    let lang = "plain";
    if (
      contentType !== undefined &&
      contentType !== "" &&
      str !== undefined &&
      str !== ""
    ) {
      if (contentType.includes("json")) {
        lang = "json";
        str = JSON.stringify(JSON.parse(str), null, 2);
      } else if (contentType.includes("xml")) {
        lang = "xml";
        str = prettyPrintXml(str);
      } else if (contentType.includes("text/html")) {
        lang = "html";
        // str = prettyPrintXml(str)
      }
    } else {
      if (hasJsonStructure(str)) {
        lang = "json";
        str = JSON.stringify(JSON.parse(str), null, 2);
      }
    }

    return (
      <AnteonEditor
        lang={lang}
        value={str}
        onChange={(val) => {
          return "";
        }}
        readonly={true}
        maxHeight="32rem"
      />
    );
  };

  return (
    <Card className="debugCard">
      <CardTitle className="smallCardTitle mb1rem alignCenter justify-content-space-between">
        <span>
          {Object.keys(allText).length > 0 && Object.keys(allText).at(0)}
        </span>

        {/* Copy Button */}
        {body !== "" && (
          <span className="debugBodyCopyButton">
            <Button.Ripple
              className="btn-icon ml-1"
              color="outline-primary"
              size="sm"
              disabled={isHttp()}
              onClick={(e) => copyToClipBoardOnClick(e, body)}>
              <Copy size={14} /> Copy
            </Button.Ripple>
          </span>
        )}
      </CardTitle>
      <Collapse isOpened={true}>
        <CardBody className="clearPadding">{formatBody(body)}</CardBody>
      </Collapse>
    </Card>
  );
};

const ShowAllTruncatedTextInModal = ({
  toggle,
  isModalOpen,
  text,
  allText
}) => {
  return (
    <Fragment>
      <Modal
        isOpen={isModalOpen}
        toggle={toggle}
        size="xl"
        scrollable={true}
        style={{ height: "90%" }}>
        <ModalHeader toggle={toggle}>Received Details</ModalHeader>
        <ModalBody>
          {<GetBodyWithCodeEditor body={text} allText={allText} />}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            <Fragment>
              <span className="align-middle">Close</span>
            </Fragment>
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export const truncateWithTooltip = (str, n = 20, customId = "") => {
  let id = "n" + customId || uuidv4();
  id = id.replace(/[^a-zA-Z0-9-]+/g, "");

  return str?.length > n ? (
    <>
      {" "}
      <span id={id}>{str.substr(0, n - 1) + "..."}</span>{" "}
      <UncontrolledTooltip placement="top" target={id}>
        {str}
      </UncontrolledTooltip>
    </>
  ) : (
    <span>{str}</span>
  );
};

export const TruncateWithTooltipAndModal = ({
  str,
  n = 20,
  showDetailsInModal = false,
  allStr
}) => {
  const id = useRef("n" + uuidv4()); // Use "ref" insead of id: https://stackoverflow.com/questions/50454676/reactstrap-tooltip-causes-an-error-the-target-tooltipexample-could-not-be-ide
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return str.length > n ? (
    <>
      {" "}
      <span ref={id}>{str.substr(0, n - 1) + "..."}</span>{" "}
      {showDetailsInModal ? (
        <a className="text-primary" onClick={toggle}>
          Show All →
        </a>
      ) : null}
      <UncontrolledTooltip placement="top" target={id}>
        {str}
      </UncontrolledTooltip>
      {showDetailsInModal ? (
        <ShowAllTruncatedTextInModal
          toggle={toggle}
          isModalOpen={isModalOpen}
          text={str}
          allText={allStr}
        />
      ) : null}
    </>
  ) : (
    <span>{str}</span>
  );
};

export const formatDateToDateWithHours = (
  value,
  formatting = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  },
  isGB = false
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat(isGB ? "en-GB" : "en-US", formatting).format(
    new Date(value)
  );
};

export const bytesToSize = (bytes, decimalPoint = 0) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0 || bytes === 0.0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i)?.toFixed(decimalPoint)} ${sizes[i]}`;
};

export const convertNanoseconds = (nanoseconds) => {
  const milliseconds = Math.floor(nanoseconds / 1e6); // Convert nanoseconds to milliseconds
  const seconds = Math.floor(milliseconds / 1000); // Convert milliseconds to seconds
  const minutes = Math.floor(seconds / 60); // Convert seconds to minutes
  const hours = Math.floor(minutes / 60); // Convert minutes to hours

  const remainingMilliseconds = milliseconds % 1000;
  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;

  return {
    hours,
    minutes: remainingMinutes,
    seconds: remainingSeconds,
    milliseconds: remainingMilliseconds
  };
};

export const formatSecondsToDuration = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const remainingSeconds = duration.seconds();
  let formattedDuration = "";

  let formattedDays = "";
  let formattedHours = "";
  let formattedMinutes = "";
  let formattedSeconds = "";

  if (days > 0) {
    formattedDuration += days + " days ";
    formattedDays = days + " days ";
  }
  if (hours > 0) {
    formattedDuration += hours + " h ";
    formattedHours = hours + " h ";
  }
  if (minutes > 0 && formattedHours === "" && formattedDays === "") {
    formattedDuration += minutes + " m ";
    formattedMinutes = minutes + " m ";
  }
  if (
    remainingSeconds > 0 &&
    formattedMinutes === "" &&
    formattedHours === "" &&
    formattedDays === ""
  ) {
    formattedDuration += remainingSeconds + " s";
    formattedSeconds = remainingSeconds + " s";
  }
  return formattedDuration.trim();
};

// format milliseconds to date time by moment
export const formatMillisecondsToDateTime = (milliseconds) => {
  const targetDate = moment(milliseconds);
  const currentDate = moment();

  const duration = moment.duration(currentDate.diff(targetDate));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} `;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else {
    return `${seconds} second${seconds !== 1 ? "s" : ""}`;
  }
};

// format microseconds to date time by timestamp
export const formatMicrosecondsToSecondMilliMicro = (microseconds) => {
  if (microseconds > 999999) {
    //if more than 1 second
    return Math.floor(microseconds / 1000000) + " s";
  } else if (microseconds > 999) {
    //if more than 1ms
    return Math.round(microseconds / 10) / 100 + " ms";
  } else {
    return microseconds + " μs";
  }
};

const addLeadingZeroes = (timestamp) => {
  // Extract the part between T and Z, representing time
  const timePart = timestamp.split("T")[1].split("Z")[0];

  // Split into hours, minutes, and seconds with fractions
  const [hours, minutes, secondsFraction] = timePart.split(":");
  const [seconds, fractionsOfSecond = ""] = secondsFraction.split(".");

  // Pad fractionsOfSecond with leading zeroes until its length is 6 (microseconds precision)
  const paddedFractionsOfSecond = fractionsOfSecond.padStart(6, "0");

  // Reconstruct the timestamp with padded fractions
  const newTimestamp = `${
    timestamp.split("T")[0]
  }T${hours}:${minutes}:${seconds}.${paddedFractionsOfSecond}Z`;

  return newTimestamp;
};

// Test the function:
// const timestamp = '2024-02-23T13:20:18.002Z';
// console.log(addLeadingZeroes(timestamp));  // output: 2024-02-23T13:20:18.000002Z

export const getTimeDiffInMicroSeconds = (
  startDateTimestamp,
  endDateTimeStamp
) => {
  const startDate = addLeadingZeroes(startDateTimestamp);
  const endDate = addLeadingZeroes(endDateTimeStamp);

  const microDiff =
    parseInt(endDate.slice(-4, -1)) - parseInt(startDate.slice(-4, -1));

  const milliDif = moment(endDate).diff(startDate, "milliseconds");

  return milliDif * 1000 + microDiff;
};

export const calculateTracingDuration = (duration) => {
  if (duration > 999) {
    return Math.round(duration / 100) / 10 + " s";
  } else if (duration >= 1) {
    return Math.round(duration * 10) / 10 + " ms";
  } else {
    return Math.round(duration * 1000) + " μs";
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "Tb", "Pb", "Eb", "Zb", "Yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i))?.toFixed(dm))} ${sizes[i]}`;
};

export const formatWatts = (watts, decimals = 2) => {
  if (!+watts) return "0 Watts";

  if (Math.abs(watts) < 1) {
    const dm = decimals < 0 ? 0 : decimals;
    return `${parseFloat(watts.toFixed(dm))} Watts`;
  }

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Watts", "KW", "MW", "GW", "Tw", "Pw", "Ew", "Zw", "Yw"];

  const i = Math.floor(Math.log(watts) / Math.log(k));

  return `${parseFloat((watts / Math.pow(k, i))?.toFixed(dm))} ${sizes[i]}`;
};

export const formatMebibytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 MiB";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i))?.toFixed(dm))} ${sizes[i]}`;
};

export const formatNumbers = (num, decimals = 2) => {
  if (!+num) return "0";

  if (Math.abs(num) < 1) {
    const dm = decimals < 0 ? 0 : decimals;
    return `${parseFloat(num.toFixed(dm))} Watts`;
  }

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["", "K", "M", "B", "T", "Qa", "Qi", "Sx", "Sp", "Oc"];

  const i = Math.floor(Math.log(num) / Math.log(k));

  return `${parseFloat((num / Math.pow(k, i))?.toFixed(dm))} ${sizes[i]}`;
};
