// ** Initial State
const initialState = {
  isZombieFilterActive: false,
  isShowUnknownConnections: false,
  isRPSFilterActive: false,
  isLatencyFilterActive: false,
  isStatusFilterActive: false,
  isProtocolFilterActive: false,
  latencyFilter: [],
  rpsFilter: [],
  searchedNodes: {},
  hoveredNode: undefined,
  httpStatuses: [1, 2, 3, 4, 5],
  protocols: [
    "POSTGRES",
    "AMQP",
    "HTTP",
    "HTTPS",
    "REDIS",
    "gRPC",
    "KAFKA",
    "MYSQL",
    "MONGO"
  ],
  allProtocols: [
    "HTTP",
    "HTTPS",
    "POSTGRES",
    "AMQP",
    "REDIS",
    "gRPC",
    "KAFKA",
    "MYSQL",
    "MONGO"
  ],
  allHttpStatuses: [1, 2, 3, 4, 5],
  distTracingFilter: false,
  monitoringActiveTab: "0",
  selectedNode: null,
  selectedNodes: [
    {
      label: "api-alaz-staging.getanteon.com:443",
      background: "success",
      textColor: "white",
      trafficTo: [
        {
          from_type: "daemon_set",
          from_id: "411f5628-cdd9-4969-8555-6f9d318bf18c",
          to_port: 443,
          protocols: ["HTTPS"],
          status_codes: [200],
          to_type: "outbound",
          to_id: "api-alaz-staging.getanteon.com:443",
          endpoints_min_rps: 0.07,
          endpoints_max_rps: 8.803333333333333,
          endpoints_min_latency: 252956007.15151516,
          endpoints_max_latency: 725472370.1818181,
          rps: 12.98,
          avg_latency: 287334080.1692347,
          incomingName: "alaz-daemonset"
        }
      ],
      nodeType: "newOutbound",
      ports: [
        {
          port: 443,
          protocol: "TCP"
        }
      ],
      neighbours: {
        incoming: [
          {
            type: "daemon_set",
            uid: "411f5628-cdd9-4969-8555-6f9d318bf18c"
          }
        ],
        outgoing: []
      },
      id: "api-alaz-staging.getanteon.com:443",
      protocol: "HTTPS",
      isZombie: false,
      pointedTraffic: []
    },
    {
      label: "alaz-backend-loglistener-deployment",
      background: "success",
      textColor: "white",
      ports: [],
      namespace: "anteon-prod",
      trafficFrom: [
        {
          from_type: "deployment",
          from_id: "4e00f531-fa4e-40cf-9b28-6b6a5e4375bf",
          to_port: 6381,
          protocols: ["REDIS"],
          status_codes: [1],
          to_type: "outbound",
          to_id: "anteon-prod-databases.internal.cloudapp.net.",
          endpoints_min_rps: 0.26,
          endpoints_max_rps: 0.26,
          endpoints_min_latency: 292109.9743589744,
          endpoints_max_latency: 292109.9743589744,
          rps: 0.26,
          avg_latency: 292109.9743589744
        },
        {
          from_type: "deployment",
          from_id: "4e00f531-fa4e-40cf-9b28-6b6a5e4375bf",
          to_port: 5432,
          protocols: ["POSTGRES"],
          status_codes: [1],
          to_type: "outbound",
          to_id: "Postgres-1",
          endpoints_min_rps: 0.0033333333333333335,
          endpoints_max_rps: 0.9666666666666667,
          endpoints_min_latency: 300744.25,
          endpoints_max_latency: 24076584.9375,
          rps: 3.67,
          avg_latency: 5073457.268846503
        }
      ],
      nodeType: "deployment",
      neighbours: {
        incoming: [],
        outgoing: [
          {
            type: "outbound",
            uid: "anteon-prod-databases.internal.cloudapp.net."
          },
          {
            type: "outbound",
            uid: "Postgres-1"
          }
        ]
      },
      id: "4e00f531-fa4e-40cf-9b28-6b6a5e4375bf",
      protocol: "",
      isZombie: false,
      pointedTraffic: []
    },
    {
      label: "milvus-pulsar-proxy",
      background: "success",
      textColor: "white",
      ports: [
        {
          port: 6650,
          protocol: "TCP"
        }
      ],
      namespace: "milvus",
      trafficTo: [
        {
          from_type: "deployment",
          from_id: "ab4707a1-5936-4ad7-ac07-b2329fa96e5b",
          to_port: 6650,
          protocols: ["gRPC", "HTTPS"],
          status_codes: [0, 200],
          to_type: "service",
          to_id: "f09dfe2d-fc2f-4ed2-9c42-46623da30f53",
          pointed: {
            "f24b1191-99b4-412d-b9ab-c00cf60c794e": {
              type: "stateful_set",
              pointed_port: 6650
            }
          },
          endpoints_min_rps: 0.05,
          endpoints_max_rps: 0.26,
          endpoints_min_latency: 4354131.8,
          endpoints_max_latency: 4413493.615384615,
          rps: 0.31,
          avg_latency: 4403919.129032258,
          incomingName: "milvus-rootcoord"
        }
      ],
      pointedTo: [
        {
          type: "stateful_set",
          pointed_port: 6650,
          outgoingName: "milvus-pulsar-proxy"
        }
      ],
      nodeType: "service",
      neighbours: {
        incoming: [
          {
            type: "deployment",
            uid: "ab4707a1-5936-4ad7-ac07-b2329fa96e5b"
          }
        ],
        outgoing: [
          {
            type: "stateful_set",
            uid: "f24b1191-99b4-412d-b9ab-c00cf60c794e"
          }
        ]
      },
      id: "f09dfe2d-fc2f-4ed2-9c42-46623da30f53",
      protocol: "gRPC",
      isZombie: false,
      pointedTraffic: []
    }
  ]
};

const ServiceMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER_DATA":
      return {
        ...state,
        ...action.value
      };
    case "SET_SEARCHED_NODES":
      return {
        ...state,
        searchedNodes: action.value
      };
    case "SET_HOVERED_NODE":
      return {
        ...state,
        hoveredNode: action.value
      };
    case "RESET_FILTERS":
      return {
        ...state,
        ...initialState
      };
    case "DIST_TRACING_FILTER":
      return {
        ...state,
        distTracingFilter: action.value
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        monitoringActiveTab: action.value
      };
    case "SET_SELECTED_NODES":
      let selectedNodes = state.selectedNodes.filter(
        (node) => node.id !== action.value.id
      );
      selectedNodes.push(action.value);
      return {
        ...state,
        selectedNodes,
        selectedNode: action.value
      };
    case "SET_SELECTED_NODES_CTRL":
      let selectedNodesCTRL = state.selectedNodes.filter(
        (node) => node.id !== action.value.id
      );
      selectedNodesCTRL.push(action.value);
      return {
        ...state,
        selectedNodes: selectedNodesCTRL
      };
    case "REMOVE_NODE_SELECTION":
      let newActiveIndex = state.selectedNodes.findIndex(
        (obj) => obj.id === action.value.id
      );

      if (state.selectedNode?.id === action.value?.id) {
        newActiveIndex = newActiveIndex - 1;
      }

      const removedArray = state.selectedNodes.filter(
        (node) => node.id !== action.value.id
      );
      let newSelected = null;
      if (state.selectedNode && removedArray.length > 0) {
        newActiveIndex = Math.max(newActiveIndex, 0);
        newActiveIndex = Math.min(newActiveIndex, removedArray.length - 1);
        newSelected = removedArray[newActiveIndex];
      }

      const newState = {
        ...state,
        selectedNodes: removedArray,
        selectedNode: newSelected
      };
      return newState;
    case "HANDLE_NODE_TAB":
      return {
        ...state,
        selectedNode: action.value
      };
    default:
      return state;
  }
};

export default ServiceMapReducer;
