import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";
var moment = require("moment"); // Import Moment.js

export const monitorApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Monitor", "Metrics"],
  reducerPath: "monitorApi",
  keepUnusedDataFor: 120,
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getMonitorings: build.query({
      query: () => "/alaz/cluster",
      providesTags: ["Monitor"]
    }),
    getMetrics: build.query({
      query: ({ id, instance, timeBox, timeRange }) =>
        `/alaz/metrics/?monitoring_id=${id}&instance=${instance}&end_time=${
          timeRange ? moment().millisecond(0).valueOf() : timeBox?.endDate
        }&start_time=${
          timeRange
            ? moment()
                .millisecond(0)
                .subtract(timeRange, "milliseconds")
                .valueOf()
            : timeBox?.startDate
        }`,
      providesTags: ["Metrics"],
      extraOptions: { maxRetries: 1 }
    }),
    getMetricInstances: build.query({
      query: ({ id, timeBox, timeRange }) =>
        `/alaz/metrics/instances/?monitoring_id=${id}&end_time=${
          timeRange ? moment().millisecond(0).valueOf() : timeBox?.endDate
        }&start_time=${
          timeRange
            ? moment()
                .millisecond(0)
                .subtract(timeRange, "milliseconds")
                .valueOf()
            : timeBox?.startDate
        }`,
      providesTags: ["Metrics"],
      extraOptions: { maxRetries: 1 }
    }),
    getMonitoring: build.query({
      query: (id) => `/monitor/?test_id=${id}`,
      providesTags: ["Monitor"]
    }),
    getRequests: build.query({
      query: ({
        id,
        selectedNamespaces = [],
        timeBox,
        timeRange,
        connections
      }) =>
        `/alaz/summary/?monitoring_id=${id}&get_all_resources=true&end_time=${
          timeRange ? moment().millisecond(0).valueOf() : timeBox?.endDate
        }&start_time=${
          timeRange
            ? moment()
                .millisecond(0)
                .subtract(timeRange, "milliseconds")
                .valueOf()
            : timeBox?.startDate
        }${
          selectedNamespaces?.length > 0
            ? `&namespaces=${selectedNamespaces.join(",")}`
            : ""
        }&connections=${!!connections}`,
      keepUnusedDataFor: 1
    }),
    getNodeDetails: build.query({
      query: ({
        id,
        monitoringId,
        timeBox,
        type,
        selectedNamespaces,
        connections
      }) =>
        `/alaz/resource/?monitoring_id=${monitoringId}&resource_uid=${id}&get_all_resources=true&end_time=${
          timeBox?.endDate
        }&start_time=${timeBox?.startDate}&resource_type=${
          type === "newOutbound" ? "outbound" : type
        }${
          selectedNamespaces?.length > 0
            ? `&namespaces=${selectedNamespaces.join(",")}`
            : ""
        }&connections=${!!connections}`,
      keepUnusedDataFor: 3600
    }),
    getNodesEndpoints: build.query({
      query: ({
        monitoringId,
        toType,
        toUid,
        toPort,
        fromType,
        fromUid,
        protocol,
        page,
        sortBy,
        sortWith,
        selectedStatuses,
        timeBox,
        selectedNamespaces = [],
        connections,
        eventType
      }) =>
        `/alaz/resource/endpoints?monitoring_id=${monitoringId}&sort_by=${sortBy}&sort_with=${sortWith}&page_num=${page}&to_uid=${toUid}&to_type=${toType}&to_port=${toPort}&from_uid=${fromUid}&from_type=${fromType}&get_all_resources=true&end_time=${
          timeBox?.endDate
        }&start_time=${
          timeBox?.startDate
        }&status_codes=${selectedStatuses}&protocol=${protocol}${
          selectedNamespaces?.length > 0
            ? `&namespaces=${selectedNamespaces.join(",")}`
            : ""
        }&event_type=${eventType}&connections=${!!connections}`,
      keepUnusedDataFor: 3600
    }),
    addMonitor: build.mutation({
      query: (name) => ({
        url: "/alaz/cluster",
        method: "POST",
        body: { name: name }
      }),
      invalidatesTags: ["Monitor"]
    }),
    removeMonitor: build.mutation({
      query: (monitoring_id) => ({
        url: `/alaz/cluster?monitoring_id=${monitoring_id}`,
        method: "DELETE",
        body: { monitoring_id: monitoring_id }
      }),
      invalidatesTags: ["Monitor"]
    }),
    renameMonitor: build.mutation({
      query: (body) => ({
        url: `/alaz/cluster?monitoring_id=${body?.monitoring_id}`,
        method: "PUT",
        body: body
      }),
      invalidatesTags: ["Monitor"]
    }),
    getResourceMetrics: build.query({
      query: ({ id, monitoringId, timeBox, type, selectedNamespaces = [] }) =>
        `/alaz/resource/metrics?monitoring_id=${monitoringId}&resource_uid=${id}&end_time=${
          timeBox?.endDate
        }&start_time=${timeBox?.startDate}&resource_type=${
          type === "newOutbound" ? "outbound" : type
        }${
          selectedNamespaces?.length > 0
            ? `&namespaces=${selectedNamespaces.join(",")}`
            : ""
        }`,
      keepUnusedDataFor: 3600
    }),
    getLogResources: build.query({
      query: ({ monitoringId, selectedNamespaces = [] }) =>
        `/alaz/log_resources/?monitoring_id=${monitoringId}${
          selectedNamespaces?.length > 0
            ? `&namespace=${selectedNamespaces.join(",")}`
            : ""
        }`,
      keepUnusedDataFor: 3600
    })
  })
});

export const {
  useGetMonitoringsQuery,
  useAddMonitorMutation,
  useRemoveMonitorMutation,
  useGetRequestsQuery,
  useRenameMonitorMutation,
  useGetMetricsQuery,
  useGetMetricInstancesQuery,
  useGetNodesEndpointsQuery,
  useGetNodeDetailsQuery,
  useGetResourceMetricsQuery,
  useGetLogResourcesQuery
} = monitorApi;
