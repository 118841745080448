import Avatar from "@components/avatar";
import { Fragment } from "react";
import { AlertCircle, Coffee, ThumbsUp } from "react-feather";
import { Slide, toast } from "react-toastify";

const ToastContent = ({ title, desc, icon, color }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={color} icon={icon} />
        <h6 className="toast-title font-weight-bold">{title}</h6>
      </div>
    </div>
    {desc && (
      <div className="toastify-body">
        <span>{desc}</span>
      </div>
    )}
  </Fragment>
);

export const WelcomeToast = ({ name }) => {
  return (
    <ToastContent
      title={"Welcome," + name}
      desc="You have successfully logged in to Anteon. Now you can start to explore. Enjoy!"
      icon={<Coffee size={12} />}
      color="success"
    />
  );
};

export const SuccessToast = ({ title = "Success", desc }) => {
  return (
    <ToastContent
      title={title}
      desc={desc}
      icon={<ThumbsUp size={12} />}
      color="success"
    />
  );
};

export const ErrorToast = ({ title = "Error", desc }) => {
  return (
    <ToastContent
      title={title}
      desc={desc}
      icon={<AlertCircle size={12} />}
      color="danger"
    />
  );
};

export const WarningToast = ({ title = "Warning", desc }) => {
  return (
    <ToastContent
      title={title}
      desc={desc}
      icon={<ThumbsUp size={12} />}
      color="warning"
    />
  );
};

export const showToast = (toastContent, duration = 2000, state = "success") => {
  if (state === "error") {
    toast.error(toastContent, {
      transition: Slide,
      hideProgressBar: true,
      autoClose: duration
    });
  } else if (state === "warning") {
    toast.warning(toastContent, {
      transition: Slide,
      hideProgressBar: true,
      autoClose: duration
    });
  } else {
    toast.success(toastContent, {
      transition: Slide,
      hideProgressBar: true,
      autoClose: duration
    });
  }
};
