import { createSlice } from "@reduxjs/toolkit";
import { List, Map } from "immutable";
import { cloneDeep } from "lodash";

// ** Initial State
/** we are using Map to create the initial state object.
 *  Map is an immutable object that allows us to set and retrieve values using a key.
 *  List is an immutable object that allows us to set and retrieve values using an index.
 **/
const initialState = Map({
  delimiter: ",",
  vars: List([]),
  allow_quota: false,
  order: "random",
  skip_first_line: true,
  skip_empty_line: true
});

export const loadTestUploadFileSlice = createSlice({
  name: "loadTestUploadFileSlice",
  initialState: initialState.toJS(),
  reducers: {
    setVars: (state, action) => {
      const newVars = List(action.payload).toJS();
      state.vars = cloneDeep(newVars);
    },

    setDelimeter: (state, action) => {
      let clonedDelimeter = cloneDeep(action.payload);
      state.delimiter = clonedDelimeter;
    },
    setAllowQuota: (state, action) => {
      state.allow_quota = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setSkipFirstLine: (state, action) => {
      state.skip_first_line = action.payload;
    },
    setSkipEmptyLine: (state, action) => {
      state.skip_empty_line = action.payload;
    }
  }
});

export const {
  setVars,
  setDelimeter,
  setAllowQuota,
  setOrder,
  setSkipFirstLine,
  setSkipEmptyLine
} = loadTestUploadFileSlice.actions;

export default loadTestUploadFileSlice.reducer;
