import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const aiAnalyseApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "aiAnalyseApi",
  keepUnusedDataFor: 1,
  endpoints: (build) => ({
    getAnalyse: build.query({
      query: (testId) => `/ai_analyzer/?test_id=${testId}`
    }),
    getSupportedCountries: build.query({
      query: () => `/get_supported_countries/`
    })
  })
});

export const { useLazyGetAnalyseQuery, useGetSupportedCountriesQuery } =
  aiAnalyseApi;
