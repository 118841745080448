// ** UseJWT import to get config
import UseJwt from "@src/auth/jwt/useJwt";
import sysConfig from "@src/configs/sysConfig";
import { saveUserData } from "@utils";
const config = UseJwt.jwtConfig;

// ** Handle User Login
export const handleLogin = (userData, accessToken, refreshToken) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN",
      data: userData,
      config,
      [config.storageTokenKeyName]: userData[config.storageTokenKeyName],
      [config.storageRefreshTokenKeyName]:
        userData[config.storageRefreshTokenKeyName]
    });

    // ** Add to user, accessToken & refreshToken to localStorage
    saveUserData(userData);
    localStorage.setItem(
      config.storageTokenKeyName,
      JSON.stringify(accessToken)
    );
    localStorage.setItem(
      config.storageRefreshTokenKeyName,
      JSON.stringify(refreshToken)
    );
    localStorage.setItem("welcomed", !userData["onboard"]); //make true to !userData["onboard"] to open wellcome
    //** If userData.onboard is true, set show email-marketing-check to localstorage and show marketing check first*/

    localStorage.setItem("show-email-marketing-modal", !userData["onboard"]);
  };
};

// ** Handle User Logout
export const handleLogout = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_REDUX"
    });
    dispatch({
      type: "LOGOUT",
      [config.storageTokenKeyName]: null,
      [config.storageRefreshTokenKeyName]: null
    });

    // Product Fruits removed from the application
    if (sysConfig.onboardingTool && sysConfig.workspaceCode) {
      window?.productFruits?.services?.destroy();
    }

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem("userData");
    localStorage.removeItem("welcomed");
    localStorage.removeItem("show-email-marketing-modal");
    localStorage.removeItem("setAlreadyFetchingAccessToken");
    localStorage.removeItem(config.storageTokenKeyName);
    localStorage.removeItem(config.storageRefreshTokenKeyName);
    localStorage.removeItem("impersonated");
  };
};
