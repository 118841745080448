import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadTour: null,
  tourInstance: null,
  tourStarted: false,
  loadTourStarted: false
};
export const tourSlice = createSlice({
  name: "tourSlice",
  initialState,
  reducers: {
    setLoadTourSliceData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.loadTour = action.payload;
    },
    setTourInstance: (state, action) => {
      state.tourInstance = action.payload;
    },
    setTourStarted: (state, action) => {
      state.tourStarted = action.payload;
    },
    setLoadTourStarted: (state, action) => {
      state.loadTourStarted = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setLoadTourSliceData,
  setTourInstance,
  setTourStarted,
  setLoadTourStarted
} = tourSlice.actions;

export default tourSlice.reducer;
