// ** Initial State
const initialState = {
  environment_id: { id: null, name: "No Environment" },
  selected_test_data_list: [],
  activeStepNumber: 0,
  utilsVariables: [
    {
      title: "rand()",
      description: {
        value:
          "Chooses a random item from an array defined in Environment or Captured Variable.",
        randUtil: "function"
      }
    }
  ]
};

const EnvironmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_ENVIRONMENT":
      return {
        ...state,
        environment_id: { ...action.value }
      };
    case "SET_ACTIVE_TESTDATA":
      return {
        ...state,
        selected_test_data_list: [...action.value]
      };
    case "SET_CAPTURED_ENVIRONMENTS":
      return {
        ...state,
        capturedEnvironments: { ...action.value }
      };
    case "SET_ACTIVE_STEP_NUMBER":
      return {
        ...state,
        activeStepNumber: action.value
      };

    default:
      return state;
  }
};

export default EnvironmentReducer;
