import { uuidv4 } from "@src/utility/Utils";
import getValuesDisplayVersion from "src/@core/components/auto-complete-special/getValuesDisplayVersion";

/*
 *
 * @param {data} filteredData[activeSuggestion]
 * @param {checkTextColor} checkTextColor function to check text color for special characters
 * @param {filterKey} filterKey is the key to filter the data
 * @param {descriptionKey} descriptionKey is the key to filter the description in the data
 * @param {isValidArray} is to check if the data is an array or not
 * @param {getEnvironmentName} getEnvironmentName function to get the name of the environment
 * @returns
 */
export const hover = (
  data,
  filterKey = "title",
  descriptionKey = "description",
  getEnvironmentName
) => {
  if (!data) {
    return <span className="text-danger">Unknown variable name.</span>;
  }
  if (
    data &&
    data[filterKey]?.slice(0, 1) === "_" &&
    (data[descriptionKey]?.randUtil === null ||
      data[descriptionKey]?.randUtil === undefined) &&
    data[descriptionKey]?.from === undefined &&
    (data[descriptionKey]?.value === null ||
      data[descriptionKey]?.value === undefined)
  ) {
    //** dynamic variables
    return (
      <span key={Math.random()}>
        <strong className="text-info">Dynamic Variable:</strong>{" "}
        <span className="normal-text-color">{data[descriptionKey]}</span>
      </span>
    );
  } else if (
    data &&
    data[descriptionKey]?.value &&
    data[descriptionKey]?.randUtil !== null &&
    data[descriptionKey]?.randUtil !== undefined &&
    data[descriptionKey]?.randUtil === "function"
  ) {
    //** Utils variables

    return (
      <span key={"desc" + uuidv4()}>
        <strong className="text-info">Function: </strong>
        <span className="normal-text-color">
          {data[descriptionKey]?.value}{" "}
        </span>
      </span>
    );
  } else if (
    data &&
    data?.variableType &&
    data?.variableType === "environment" &&
    !data[descriptionKey]?.from
  ) {
    //** Environment variables

    const getTextColor = "colored-part";
    return (
      <span key={Math.random()}>
        <span>
          <strong className={getTextColor}>Environment: </strong>
        </span>
        <span> {getEnvironmentName} </span>
        <br />
        <span>
          <strong className={getTextColor}>Value:</strong>{" "}
        </span>
        <span>{getValuesDisplayVersion(data[descriptionKey]?.value)}</span>
      </span>
    );
  } else if (
    data &&
    data[descriptionKey]?.value?.from &&
    (data[descriptionKey]?.type === null ||
      data[descriptionKey]?.type === undefined)
  ) {
    //** capture Env variables less than and equal to stepnumber data
    const getTextColor = "colored-part";
    return (
      <span key={Math.random()}>
        <span className={getTextColor}>Captured variable at </span>
        <span>Step {data[descriptionKey]?.stepNumber}</span>
      </span>
    );
  } else if (data && data?.variableType && data?.variableType === "test_data") {
    //** Test Data variables
    const getTextColor = "colored-part";
    return (
      <span key={Math.random()}>
        <span>
          <strong className={getTextColor}>Test Data: </strong>
        </span>
        <span> {data.name} </span>
        <br />
        <span className={getTextColor}>
          <strong>Header:</strong>{" "}
        </span>
        <span>{getValuesDisplayVersion(data[descriptionKey]?.tag)}</span>
        <br />
        <span>
          <strong className={getTextColor}>Type:</strong>{" "}
        </span>
        <span>{getValuesDisplayVersion(data[descriptionKey]?.type)}</span>
      </span>
    );
  } else {
    //** Default description
    return <span key={Math.random()}>{data[descriptionKey]}</span>;
  }
};
