import baseQueryConfig from "@src/redux/storeConfig/baseQueryConfig";
import { Mutex } from "async-mutex";
// create a new mutex not to send refresh tokens requests for all failing requests
const mutex = new Mutex();
const baseQuery = baseQueryConfig;
const baseQueryWithReauth = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  let result = await baseQuery(args, api, extraOptions);

  return result;
};

export default baseQueryWithReauth;
