const isObject = (item) => {
  return typeof item === "object";
};
const getValuesDisplayVersion = (value) => {
  if (isObject(value)) {
    return JSON.stringify(value);
  } else if (
    value + "".trim()?.toString()?.toLowerCase() === "true" ||
    value + "".trim()?.toString()?.toLowerCase() === "false"
  ) {
    return value + ""?.toString()?.toLowerCase() === "true" ? "true" : "false";
  } else if (!isNaN(value)) {
    return value + "";
  } else {
    return value + "";
  }
};

export default getValuesDisplayVersion;
