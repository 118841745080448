import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const teamMembersApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "TeamMembers",
    "Team",
    "UpdateTeamMembers",
    "CreateTeamMembers",
    "DeleteTeamMembers"
  ],
  reducerPath: "teamMembersApi",
  keepUnusedDataFor: 120,
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getTeamMembers: build.query({
      query: () => "/account/team_member/",
      providesTags: ["TeamMembers"]
    }),
    getTeam: build.query({
      query: () => "/account/team/",
      providesTags: ["Team"],
      invalidatesTags: ["TeamMembers", "Dashboard"],
      keepUnusedDataFor: 120
    }),
    createTeam: build.mutation({
      query: () => ({
        url: "/account/team/",
        method: "POST"
      }),
      invalidatesTags: ["TeamMembers", "Team"]
    }),
    deleteTeam: build.mutation({
      query: () => ({
        url: "/account/team/",
        method: "DELETE"
      }),
      invalidatesTags: ["TeamMembers", "Team"]
    }),
    // A mutation endpoint with a single argument:
    updateTeamName: build.mutation({
      query: (newTeamName) => ({
        url: "/account/team/",
        method: "PUT",
        body: { name: newTeamName }
      }),
      invalidatesTags: ["TeamMembers", "Team"]
    }),
    // create a team member with email and name
    createTeamMember: build.mutation({
      query: (data) => ({
        url: "/account/team_member/",
        method: "POST",
        body: data //data contains email and name object
      }),
      providesTags: ["CreateTeamMembers"],
      invalidatesTags: ["TeamMembers"]
    }),
    // update a team member with name
    updateTeamMember: build.mutation({
      query: (data) => ({
        url: "/account/team_member/",
        method: "PUT",
        body: { name: data.name },
        providesTags: ["UpdateTeamMembers"],
        invalidatesTags: ["TeamMembers", "Team"]
      })
    }),
    // delete a team member with email
    deleteTeamMember: build.mutation({
      query: (email) => ({
        url: "/account/team_member/",
        method: "DELETE",
        body: { email: email }
      }),
      providesTags: ["DeleteTeamMembers"],
      invalidatesTags: ["TeamMembers"]
    })
  })
});

export const {
  useLazyGetTeamMembersQuery,
  useLazyGetTeamQuery,
  useCreateTeamMutation,
  useDeleteTeamMutation,
  useUpdateTeamNameMutation,
  useCreateTeamMemberMutation,
  useUpdateTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useGetTeamQuery
} = teamMembersApi;
