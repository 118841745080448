// ** Initial State
const initialState = {
  filteredCategories: null
};

const AnalyzeFailuresReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTERED_CATEGORIES":
      return {
        ...state,
        filteredCategories: { ...action.value }
      };
    default:
      return state;
  }
};

export default AnalyzeFailuresReducer;
