// ** Initial State

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tempVars: [],
  originalVars: [],
  originalData: [],
  nameOfFile: "",
  disableUploadButton: true,
  warningHeaders: []
};

export const loadTestUploadFileVariablesSlice = createSlice({
  name: "loadTestUploadFileVariablesSlice",
  initialState: initialState,
  reducers: {
    setOriginalData: (state, action) => {
      state.originalData = action.payload;
    },
    setNameOfFile: (state, action) => {
      state.nameOfFile = action.payload;
    },
    setWarningHeaders: (state, action) => {
      state.warningHeaders = action.payload;
    }
  }
});

export const { setOriginalData, setNameOfFile, setWarningHeaders } =
  loadTestUploadFileVariablesSlice.actions;

export default loadTestUploadFileVariablesSlice.reducer;
