import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";
import moment from "moment";

export const alertApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Alert", "Metrics", "MetricChoices", "SingleAlert"],
  reducerPath: "alertApi",
  keepUnusedDataFor: 120,
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getAlerts: build.query({
      query: ({ pageNumber }) => `/alerts/alert/?page_number=${pageNumber}`,
      providesTags: ["Alert"]
    }),
    getAllAlerts: build.query({
      query: () => "/alerts/alert/all/",
      providesTags: ["Alert"]
    }),
    getSingleAlert: build.query({
      query: ({ alertingId, pageNumber = 1 }) =>
        `/alerts/alert/?uid=${alertingId}`,
      providesTags: ["SingleAlert"],
      keepUnusedDataFor: 1
    }),
    getAlertHistory: build.query({
      query: ({ timeRange, uid }) =>
        `/alerts/alert/history?uid=${uid}&start_time=${moment()
          .subtract(timeRange, "milliseconds")
          .valueOf()}&end_time=${moment().valueOf()}`,
      providesTags: ["Alert"]
    }),
    getAlertTableHistory: build.query({
      query: ({ timeRange, uid, page }) =>
        `/alerts/alert/history/details?page_number=${page}&uid=${uid}&start_time=${moment()
          .subtract(timeRange, "milliseconds")
          .valueOf()}&end_time=${moment().valueOf()}`,
      providesTags: ["Alert"]
    }),
    addAlert: build.mutation({
      query: (data) => ({
        url: "/alerts/alert/",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Alert"]
    }),
    removeAlert: build.mutation({
      query: (alerting_id) => ({
        url: `/alerts/alert/?uid=${alerting_id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Alert"]
    }),
    putAlert: build.mutation({
      query: ({ alertingId, body }) => {
        return {
          url: `/alerts/alert/?uid=${alertingId}`,
          method: "PUT",
          body: body
        };
      },
      invalidatesTags: ["Alert"]
    }),
    getMetricChoices: build.query({
      query: () => `/alerts/metric_choices/`,
      providesTags: ["MetricChoices"]
    })
  })
});

export const {
  useGetAlertsQuery,
  useGetSingleAlertQuery,
  useLazyGetMetricChoicesQuery,
  useAddAlertMutation,
  useRemoveAlertMutation,
  usePutAlertMutation,
  useGetMetricChoicesQuery,
  useGetAlertHistoryQuery,
  useGetAlertTableHistoryQuery,
  useGetAllAlertsQuery
} = alertApi;
