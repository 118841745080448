export const combineTestDataVariableList = (getTestDataVariableList) => {
  let varList = [];
  if (!getTestDataVariableList) return varList;
  if (getTestDataVariableList !== null && getTestDataVariableList.length > 0) {
    getTestDataVariableList.forEach((td) => {
      Object.values(td.file_config.vars).forEach((val) => {
        let newVariableList = {};

        newVariableList["title"] = "data." + td.name + "." + val.tag;
        newVariableList["description"] = val;
        newVariableList["variableType"] = "test_data";
        newVariableList["name"] = td.name;

        varList.push(newVariableList);
      });
    });
  }
  return varList;
};

const getTempCaptureEnvArrObj = (captEnv, stepNumber) => {
  let tempArr = [];
  let collectArr = [];

  for (const [key, value] of Object.entries(captEnv)) {
    tempArr.push({ key: key, value: value });
  }

  tempArr.forEach((item, index) => {
    let tempObj = {};
    tempObj["title"] = item.key;
    tempObj["description"] = { ...item, stepNumber };
    tempObj["variableType"] = "capture_env";
    collectArr.push(tempObj);
  });

  return collectArr;
};

const combineCapturedEnvironmentVariables = (getCapturedEnvironments) => {
  //** Get capturedEnv variable list
  let varList = [];
  if (!getCapturedEnvironments) return varList;
  // loop through steps
  for (const [key, value] of Object.entries(getCapturedEnvironments)) {
    if (value["capture_env"]) {
      let tempCaptureEnvArrObj = getTempCaptureEnvArrObj(
        value["capture_env"],
        parseInt(key) + 1
      );
      varList.push({ ...tempCaptureEnvArrObj });
    }
  }
  return varList;
};

export const calculateStepCaptureds = (step, getCapturedEnvironments) => {
  const capturedVars = combineCapturedEnvironmentVariables(
    getCapturedEnvironments
  );
  const mergedArray = [];
  for (let i = 0; i < step; i++) {
    if (capturedVars[i] === undefined) continue;
    for (let j = 0; j < Object.keys(capturedVars[i])?.length; j++) {
      mergedArray.push(capturedVars[i][j]);
    }
  }
  return mergedArray;
};

export const combineUtilsVariables = (
  getUtilsVariables,
  toShowUtilsVariables
) => {
  //TODO: REFACTOR: move all modified suggestions to redux store. Then we do not need to create another state in the project files
  //** set utils variables to state from the redux store */
  let varList = [];
  if (!getUtilsVariables) return varList;
  if (
    Array.isArray(getUtilsVariables) &&
    getUtilsVariables?.length &&
    toShowUtilsVariables &&
    toShowUtilsVariables !== null &&
    toShowUtilsVariables !== undefined &&
    toShowUtilsVariables === true
  ) {
    getUtilsVariables?.forEach((item) => {
      varList.push({ ...item });
    });
  }

  return varList;
};

export const combineEnvironmentVariableList = (getEnvironmentVariableList) => {
  let varList = [];
  if (!getEnvironmentVariableList) return varList;
  getEnvironmentVariableList?.forEach((element) => {
    let newVariableList = {};
    newVariableList["title"] = element.key;
    newVariableList["description"] = element;
    newVariableList["variableType"] = "environment";

    varList.push(newVariableList);
  });

  return varList;
};
