import { historyField } from "@codemirror/commands";
import { html } from "@codemirror/lang-html";
import { javascript } from "@codemirror/lang-javascript";
import { json, jsonLanguage, jsonParseLinter } from "@codemirror/lang-json";

import { xml } from "@codemirror/lang-xml";
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language";
import { linter } from "@codemirror/lint";
import { EditorView, hoverTooltip } from "@codemirror/view";
import { useSkin } from "@hooks/useSkin";
import { tags } from "@lezer/highlight";
import {
  calculateStepCaptureds,
  combineEnvironmentVariableList,
  combineTestDataVariableList
} from "@src/components/AnteonEditor/Utils";
import { dynamicVariables } from "@src/components/TestPlan/ScenarioBuild/dynamicVariables";
import CodeMirror from "@uiw/react-codemirror";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import completeJSDoc from "@src/components/AnteonEditor/completeJSDoc";
import { hover } from "@src/components/AnteonEditor/hover";

/**
 * Anteon Editor is a generic text editor with a few builtin language supports
 *
 * props.lang     => Editor language (Supporting: json|xml|html). If not or invalid given, normal text editor will be created.
 * props.value    => Value of the editor content. If not provided, default values will be inserted into the editor content
 * props.onChange => onChange callback. Called everytime content change.
 */

// We can refer to this for more examples:
// https://github.com/akhayoon/codemirror-demo

const linterExtension = linter(jsonParseLinter());
const AnteonEditor = (props) => {
  const {
    lang,
    onChange,
    readonly = false,
    maxHeight = "50rem",
    minWidth,
    stepId = 1
  } = props;
  const getCapturedEnvironments = useSelector(
    (state) => state?.environment?.capturedEnvironments ?? []
  );
  const getSelectedTestDataList = useSelector(
    (state) => state?.environment?.selected_test_data_list
  );
  const getEnvironmentVariableList = useSelector(
    (state) => state?.environment?.environment_id?.vars
  );
  const getUtilsVariables = useSelector(
    (state) => state?.environment?.utilsVariables
  );
  const getEnvironmentName = useSelector(
    (state) => state?.environment?.environment_id?.name
  );
  const completeSuggestions = useMemo(
    () => [
      ...calculateStepCaptureds(stepId, getCapturedEnvironments),
      ...combineEnvironmentVariableList(getEnvironmentVariableList),
      ...combineTestDataVariableList(getSelectedTestDataList),
      ...dynamicVariables,
      ...getUtilsVariables
    ],
    [
      getCapturedEnvironments,
      getSelectedTestDataList,
      getEnvironmentVariableList,
      getUtilsVariables
    ]
  );

  const completeSuggestionsMap = useMemo(() => {
    const SuggestionMap = {};
    completeSuggestions.forEach((suggestion) => {
      SuggestionMap[suggestion?.title] = suggestion;
    });
    return SuggestionMap;
  }, [completeSuggestions]);

  const stateFields = { history: historyField };
  const serializedState = useRef();
  const editorValue = useRef(props.value || "");
  const jsonDefaultVal = `{

}`;
  const xmlDefaultVal = "<>";
  const htmlDefaultVal = `<html>

</html>`;

  const darkTheme = EditorView.theme(
    {
      "&": {
        color: "#56b6c2 !important",
        backgroundColor: "#364570 !important"
      },
      ".cm-content": {
        caretColor: "#61afef"
      },
      ".cm-activeLine": {
        backgroundColor: "transparent !important"
      },
      ".cm-gutters": {
        backgroundColor: "#364570 !important",
        borderRight: "1px solid #4d629d !important"
      },
      ".cm-activeLineGutter": {
        backgroundColor: "#5368a5 !important"
      },
      ".cm-cursor": {
        borderLeft: "1.2px solid #d88b44 !important"
      },
      "cm-line > span.cd": {
        color: "orange"
      },
      ".cm-tooltip-lint ": {
        left: "0 !important",
        zIndex: "9999 !important"
      },
      ".cm-tooltip .cm-tooltip-below": {
        top: "0 !important"
      }
    },
    { dark: false }
  );

  const darkHighlight = HighlightStyle.define([
    { tag: tags.propertyName, color: "#60a5fa" },
    { tag: tags.string, color: "#e879f9" },
    { tag: tags.number, color: "#a78bfa" }
  ]);

  const lightTheme = EditorView.theme(
    {
      "&": {
        color: "#56b6c2 !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #e3e0e0"
      },
      ".cm-content": {
        caretColor: "#61afef"
      },
      ".cm-activeLine": {
        backgroundColor: "transparent !important"
      },
      ".cm-gutters": {
        backgroundColor: "#ffffff !important",
        borderRight: "1px solid #e3e1e1 !important"
      },
      ".cm-activeLineGutter": {
        backgroundColor: "#ffffff !important"
      },
      ".cm-cursor": {
        borderLeft: "1.2px solid #d88b44 !important"
      },
      "cm-line > span.cd": {
        color: "orange"
      },
      ".cm-tooltip-lint ": {
        left: "0 !important",
        zIndex: "9999 !important"
      },
      ".cm-tooltip .cm-tooltip-below": {
        top: "0 !important"
      }
    },
    { dark: false }
  );

  const lightHighlight = HighlightStyle.define([
    { tag: tags.propertyName, color: "#60a5fa" },
    { tag: tags.string, color: "#e879f9" },
    { tag: tags.number, color: "#a78bfa" }
  ]);

  const [skin, setSkin] = useSkin();
  const [customTheme, setCustomTheme] = useState();
  const [customHighlight, setCustomHighlight] = useState();
  const [editorLang, setEditorLang] = useState();

  useEffect(() => {
    let val = "";
    switch (lang) {
      case "json":
        val = jsonDefaultVal;
        setEditorLang(json());
        break;
      case "html":
        val = htmlDefaultVal;
        setEditorLang(html());
        break;
      case "xml":
        val = xmlDefaultVal;
        setEditorLang(xml());
        break;
      default:
        setEditorLang(javascript());
        break;
    }

    if (
      props.value != null &&
      [jsonDefaultVal, xmlDefaultVal, htmlDefaultVal].indexOf(props.value) ===
        -1
    ) {
      val = props.value;
    }
    onChange(val);
    editorValue.current = val;
  }, [lang, props.value]);

  useEffect(() => {
    if (skin === "dark") {
      setCustomTheme(darkTheme);
      setCustomHighlight(darkHighlight);
    } else {
      setCustomTheme(lightTheme);
      setCustomHighlight(lightHighlight);
    }
  }, [skin]);
  const wordHover = hoverTooltip(
    (view, pos, side) => {
      let { from, to, text } = view?.state?.doc.lineAt(pos);
      let start = pos,
        end = pos;
      while (start > from && /[\w\.\(\)]/.test(text[start - from - 1])) start--;
      if (text[start - from - 1] !== "{" || text[start - from - 2] !== "{") {
        return;
      }
      while (end < to && /[\w\.\(\)]/.test(text[end - from])) end++;
      if (text[end - from] !== "}" || text[end - from + 1] !== "}") {
        return;
      }
      if ((start == pos && side < 0) || (end == pos && side > 0)) return null;
      return {
        pos: start,
        end,
        above: true,
        create(view) {
          const dom = document.createElement("div");
          //gets tooltip data
          const jsxElement = hover(
            completeSuggestionsMap[text.slice(start - from, end - from)],
            "title",
            "description",
            getEnvironmentName
          );
          dom.innerHTML = renderToString(jsxElement);
          return { dom };
        }
      };
    },
    { hoverTime: 30, hideOnChange: true }
  );

  const jsDocCompletions = jsonLanguage.data.of({
    autocomplete: (context) =>
      completeJSDoc(context, completeSuggestions, getEnvironmentName)
  });

  const getExtensions = useCallback(() => {
    const options = { hoverTime: 0 };
    let defaultExtensions = [
      syntaxHighlighting(customHighlight),
      json({}),
      jsDocCompletions,
      wordHover,
      editorLang
    ];
    // Add linter and lint gutter for json
    // TODO: Add linter exception for json editor
    // Example "name": {{name}}} should be acceptable

    // if (lang === "json") {
    //   defaultExtensions.push(linterExtension);
    //   defaultExtensions.push(lintGutter());
    // }
    return defaultExtensions;
  }, [editorLang]);

  return (
    // <CodeMirror typeof value === "string" ? value : ""

    <CodeMirror
      value={typeof editorValue.current === "string" ? editorValue.current : ""}
      minHeight="200px"
      maxHeight={maxHeight}
      minWidth={minWidth}
      extensions={getExtensions()}
      initialState={
        serializedState.current
          ? {
              json: JSON.parse(serializedState.current) || {},
              fields: stateFields
            }
          : undefined
      }
      onChange={(value, viewUpdate) => {
        editorValue.current = value;
        const state = viewUpdate?.state?.toJSON(stateFields);
        serializedState.current = JSON.stringify(state);
        onChange(value);
      }}
      editable={!readonly}
      theme={customTheme}
    />
  );
};

export default AnteonEditor;
