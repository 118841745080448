import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const pricingApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Pricing", "SubscriptionPlans"],
  reducerPath: "pricingApi",
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getSubscriptionPlans: build.query({
      query: () => "/payment/plan/",
      providesTags: ["SubscriptionPlans"]
    })
  })
});

export const {
  useGetSubscriptionPlansQuery,
  useLazyGetSubscriptionPlansQuery
} = pricingApi;
