import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchKey: "",
  matchLength: 0,
  searchIndex: -1
};

export const nodeSearchSlice = createSlice({
  name: "nodeSearchSlice",
  initialState,
  reducers: {
    // Service map search index
    setSearchIndexDispatcher: (state, action) => {
      state.searchIndex = action.payload;
    },

    // Service map search key
    setSearchKeyDispatcher: (state, action) => {
      state.searchKey = action.payload;
    },

    // Service map match length
    setMatchLengthDispatcher: (state, action) => {
      state.matchLength = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setSearchIndexDispatcher,
  setSearchKeyDispatcher,
  setMatchLengthDispatcher
} = nodeSearchSlice.actions;

export default nodeSearchSlice.reducer;
