import { ssoApi } from "@src/components/LoginButtonOneLoginSSO/api/loginSSOApiSlice";
import { accountApi } from "@src/views/account-settings/api/accountApiSlice";
import { transactionApi } from "@src/views/account-settings/usage-history/api/transactionApiSlice";
import { alertApi } from "@src/views/alerts/api/alertsApiSlice";
import { dashboardApi } from "@src/views/dashboard/api/dashboardApiSlice";
import { integrationsApi } from "@src/views/integrations/api/integrationsApiSlice";
import { aiAnalyseApi } from "@src/views/load/api/aiAnalyseApiSlice";
import { uploadMultipartApi } from "@src/views/load/api/uploadMultipartApiSlice";
import { environmentApi } from "@src/views/load/environments/api/environmentApiSlice";
import { monitorApi } from "@src/views/monitoring/api/monitorApiSlice";
import { pricingApi } from "@src/views/pricing/api/pricingApiSlice";
import { teamMembersApi } from "@src/views/team-settings/api/teamMembersApiSlice";
import { tracingApi } from "@src/views/tracing/api/tracingApiSlice";

const rootMiddleware = [
  dashboardApi.middleware,
  pricingApi.middleware,
  uploadMultipartApi.middleware,
  teamMembersApi.middleware,
  aiAnalyseApi.middleware,
  transactionApi.middleware,
  ssoApi.middleware,
  monitorApi.middleware,
  accountApi.middleware,
  environmentApi.middleware,
  alertApi.middleware,
  integrationsApi.middleware,
  tracingApi.middleware
];
export default rootMiddleware;
