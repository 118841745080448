// Postman equivalents: https://learning.postman.com/docs/writing-scripts/script-references/variables-list
export const dynamicVariables = [
  // Common
  {
    title: "_guid",
    description: "A v4 style guid"
  },
  {
    title: "_timestamp",
    description: "The current timestamp"
  },
  {
    title: "_isoTimestamp",
    description: "The current ISO timestamp at zero UTC"
  },
  {
    title: "_randomUUID",
    description: "A random 36-character UUID"
  },

  //Text, numbers, and colors
  {
    title: "_randomAlphaNumeric",
    description: "A random alpha-numeric character"
  },
  {
    title: "_randomBoolean",
    description: "A random boolean value (true/false)"
  },
  {
    title: "_randomInt",
    description: "A random integer between 0 and 1000"
  },
  {
    title: "_randomColor",
    description: "A random color"
  },
  {
    title: "_randomHexColor",
    description: "A random hex value"
  },
  {
    title: "_randomAbbreviation",
    description: "A random abbreviation"
  },

  // Internet and IP addresses
  {
    title: "_randomIP",
    description: "A random IPv4 address"
  },
  {
    title: "_randomIPV6",
    description: "A random IPv6 address"
  },
  {
    title: "_randomMACAddress",
    description: "A random MAC address"
  },
  {
    title: "_randomPassword",
    description: "A random 15-character alpha-numeric password"
  },
  {
    title: "_randomLocale",
    description: "A random two-letter language code (ISO 639-1)"
  },
  {
    title: "_randomUserAgent",
    description: "A random user agent"
  },
  {
    title: "_randomProtocol",
    description: "A random internet protocol"
  },
  {
    title: "_randomSemver",
    description: "A random semantic version number"
  },

  // Names
  {
    title: "_randomFirstName",
    description: "A random first name"
  },
  {
    title: "_randomLastName",
    description: "A random last name"
  },
  {
    title: "_randomFullName",
    description: "A random first and last name"
  },
  {
    title: "_randomNamePrefix",
    description: "A random name prefix (e.g. Mr., Mrs., Dr.)"
  },
  {
    title: "_randomNameSuffix",
    description: "A random name suffix (e.g. Jr., MD, PhD)"
  },

  // Profession
  {
    title: "_randomJobArea",
    description: "A random job area (e.g. branding, functionality, usability)"
  },
  {
    title: "_randomJobDescriptor",
    description:
      "A random job descriptor (e.g., senior, chief, corporate, etc.)"
  },
  {
    title: "_randomJobTitle",
    description: "A random job title (e.g. senior software developer)"
  },

  {
    title: "_randomJobType",
    description:
      "A random job type (e.g. supervisor, manager, coordinator, etc.)"
  },

  // Phone, address, and location
  {
    title: "_randomPhoneNumber",
    description: "A random 10-digit phone number"
  },
  {
    title: "_randomPhoneNumberExt",
    description: "A random phone number with extension (12 digits)"
  },
  {
    title: "_randomCity",
    description: "A random city name"
  },
  {
    title: "_randomStreetName",
    description: "A random street name"
  },
  {
    title: "_randomStreetAddress",
    description: "A random street address (e.g. 1234 Main Street)"
  },
  {
    title: "_randomCountry",
    description: "A random country"
  },
  {
    title: "_randomCountryCode",
    description: "A random 2-letter country code (ISO 3166-1 alpha-2)"
  },
  {
    title: "_randomLatitude",
    description: "A random latitude coordinate"
  },
  {
    title: "_randomLongitude",
    description: "A random longitude coordinate"
  },

  // Images
  {
    title: "_randomAvatarImage",
    description: "A random avatar image"
  },
  {
    title: "_randomImageUrl",
    description: "A URL for a random image"
  },
  {
    title: "_randomAbstractImage",
    description: "A URL for a random abstract image"
  },
  {
    title: "_randomAnimalsImage",
    description: "A URL for a random animal image"
  },
  {
    title: "_randomBusinessImage",
    description: "A URL for a random stock business image"
  },
  {
    title: "_randomCatsImage",
    description: "A URL for a random cat image"
  },
  {
    title: "_randomCityImage",
    description: "A URL for a random city image"
  },
  {
    title: "_randomFoodImage",
    description: "A URL for a random food image"
  },
  {
    title: "_randomNightlifeImage",
    description: "A URL for a random nightlife image"
  },
  {
    title: "_randomFashionImage",
    description: "A URL for a random fashion image"
  },
  {
    title: "_randomPeopleImage",
    description: "A URL for a random image of a person"
  },
  {
    title: "_randomNatureImage",
    description: "A URL for a random nature image"
  },
  // {
  //   title: "_randomSportsImage",
  //   description: "A URL for a random sports image",
  // },
  {
    title: "_randomTransportImage",
    description: "A URL for a random transportation image"
  },
  {
    title: "_randomImageDataUri",
    description: "A random image data URI"
  },

  // Finance
  {
    title: "_randomBankAccount",
    description: "A random 8-digit bank account number"
  },
  {
    title: "_randomBankAccountName",
    description:
      "A random bank account name (e.g. savings account, checking account)"
  },
  {
    title: "_randomCreditCardMask",
    description: "A random masked credit card number"
  },
  {
    title: "_randomBankAccountBic",
    description: "A random BIC (Bank Identifier Code)"
  },
  {
    title: "_randomBankAccountIban",
    description:
      "A random 15-31 character IBAN (International Bank Account Number)"
  },
  {
    title: "_randomTransactionType",
    description: "A random transaction type (e.g. invoice, payment, deposit)"
  },
  {
    title: "_randomCurrencyCode",
    description: "A random 3-letter currency code (ISO-4217)"
  },
  {
    title: "_randomCurrencyName",
    description: "A random currency name"
  },
  {
    title: "_randomCurrencySymbol",
    description: "A random currency symbol"
  },
  {
    title: "_randomBitcoin",
    description: "A random bitcoin address"
  },

  // Business
  {
    title: "_randomCompanyName",
    description: "A random company name"
  },
  {
    title: "_randomCompanySuffix",
    description: "A random company suffix (e.g. Inc, LLC, Group)"
  },
  {
    title: "_randomBs",
    description: "A random phrase of business speak"
  },
  {
    title: "_randomBsAdjective",
    description: "A random business speak adjective"
  },
  {
    title: "_randomBsBuzz",
    description: "A random business speak buzzword"
  },
  {
    title: "_randomBsNoun",
    description: "A random business speak noun"
  },

  // Catchphrases
  {
    title: "_randomCatchPhrase",
    description: "A random catchphrase"
  },
  {
    title: "_randomCatchPhraseAdjective",
    description: "A random catchphrase adjective"
  },
  {
    title: "_randomCatchPhraseDescriptor",
    description: "A random catchphrase descriptor"
  },
  {
    title: "_randomCatchPhraseNoun",
    description: "Randomly generates a catchphrase noun"
  },

  // Databases
  {
    title: "_randomDatabaseColumn",
    description: "A random database column name (e.g. updatedAt, token, group)"
  },
  {
    title: "_randomDatabaseType",
    description: "A random database type (e.g. tiny int, double, point)"
  },
  {
    title: "_randomDatabaseCollation",
    description: "A random database collation (e.g. cp1250_bin)"
  },
  {
    title: "_randomDatabaseEngine",
    description: "A random database engine (e.g. Memory, Archive, InnoDB)"
  },

  // Dates
  {
    title: "_randomDateFuture",
    description: "A random future datetime"
  },
  {
    title: "_randomDatePast",
    description: "A random past datetime"
  },
  {
    title: "_randomDateRecent",
    description: "A random recent datetime"
  },
  {
    title: "_randomWeekday",
    description: "A random week day"
  },
  {
    title: "_randomMonth",
    description: "A random month"
  },

  // Domains, emails, and usernames
  {
    title: "_randomDomainName",
    description: "A random domain name"
  },
  {
    title: "_randomDomainSuffix",
    description: "A random domain suffix (e.g. .com, .net, .org)"
  },
  {
    title: "_randomDomainWord",
    description: "A random unqualified domain name (a name with no dots)"
  },
  {
    title: "_randomEmail",
    description: "A random email address"
  },
  {
    title: "_randomExampleEmail",
    description:
      "A random email address from an “example” domain (e.g. ben@example.com)"
  },
  {
    title: "_randomUserName",
    description: "A random username"
  },
  {
    title: "_randomUrl",
    description: "A random URL"
  },

  // Files and directories
  {
    title: "_randomFileName",
    description: "A random file name (includes uncommon extensions)"
  },
  {
    title: "_randomFileType",
    description: "A random file type (includes uncommon file types)"
  },
  {
    title: "_randomFileExt",
    description: "A random file extension (includes uncommon extensions)"
  },
  {
    title: "_randomCommonFileName",
    description: "A random file name"
  },
  {
    title: "_randomCommonFileType",
    description: "A random, common file type (e.g., video, text, image, etc.)"
  },
  {
    title: "_randomCommonFileExt",
    description: "A random, common file extension (.doc, .jpg, etc.)"
  },
  {
    title: "_randomFilePath",
    description: "A random file path"
  },
  {
    title: "_randomDirectoryPath",
    description: "A random directory path"
  },
  {
    title: "_randomMimeType",
    description: "A random MIME type"
  },

  // Stores
  {
    title: "_randomPrice",
    description: "A random price between 0.00 and 1000.00"
  },
  {
    title: "_randomProduct",
    description: "A random product (e.g. shoes, table, chair)"
  },
  {
    title: "_randomProductAdjective",
    description: "A random product adjective (e.g. tasty, eco-friendly)"
  },
  {
    title: "_randomProductMaterial",
    description: "A random product material (e.g. steel, plastic, leather)"
  },
  {
    title: "_randomProductName",
    description: "A random product name (e.g. handmade concrete tuna)"
  },
  {
    title: "_randomDepartment",
    description: "A random stores department"
  },

  // Grammar
  {
    title: "_randomNoun",
    description: "A random noun"
  },
  {
    title: "_randomVerb",
    description: "A random verb"
  },
  {
    title: "_randomIngverb",
    description: "A random ing verb"
  },
  {
    title: "_randomAdjective",
    description: "A random adjective"
  },
  {
    title: "_randomWord",
    description: "A random word"
  },
  {
    title: "_randomWords",
    description: "Some random words"
  },
  {
    title: "_randomPhrase",
    description: "A random phrase"
  },

  // Lorem ipsum
  {
    title: "_randomLoremWord",
    description: "A random word of lorem ipsum text"
  },
  {
    title: "_randomLoremWords",
    description: "Some random words of lorem ipsum text"
  },
  {
    title: "_randomLoremSentence",
    description: "A random sentence of lorem ipsum text"
  },
  {
    title: "_randomLoremSentences",
    description: "A random 2-6 sentences of lorem ipsum text"
  },
  {
    title: "_randomLoremParagraph",
    description: "A random paragraph of lorem ipsum text"
  },
  {
    title: "_randomLoremParagraphs",
    description: "3 random paragraphs of lorem ipsum text"
  },
  {
    title: "_randomLoremText",
    description: "A random amount of lorem ipsum text"
  },
  {
    title: "_randomLoremSlug",
    description: "A random lorem ipsum URL slug"
  },
  {
    title: "_randomLoremLines",
    description: "1-5 random lines of lorem ipsum"
  }
];
